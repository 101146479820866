import React from 'react';
import moment from 'moment';

// Components
import Appbar from 'components/Appbar';
import DatePicker from 'lib/DatePicker';

// Utils
import buildPath from 'utils/buildPath';

class TimeRange extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      customRange: {}
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.match.params.timeRange && props.match.params.timeRange.match('custom')) {
      const timeRange = props.match.params.timeRange;
      if (!timeRange) return null;
      const startDate = timeRange.split(',')[0].replace('custom[', '');
      const endDate = timeRange.split(',')[1].replace(']', '');
      return {
        customRange: {
          startDate: startDate || null,
          endDate: endDate || null
        }
      };
    }
    return null;
  }

  componentDidMount() {}

  render() {

    if (this.props.match.params.timeRange) {
      this.isCustomRange = (this.props.match.params.timeRange).match('custom');
    }

    const periods = [{
      name: 'This Month',
      key: 'month',
      path: buildPath({ timeRange: 'month' }, this.props)
    }, {
      name: 'This Week',
      key: 'week',
      path: buildPath({ timeRange: 'week' }, this.props)
    }, {
      name: 'Today',
      key: 'day',
      path: buildPath({ timeRange: 'day' }, this.props)
    }];

    const periodNavigation = periods.map((item) => {
      const isActive = item.key === (this.props.match.params.timeRange);
      const CSSClass = `navbar-item button navbar-button ${isActive ? ' is-active' : ''}`;
      return (
        <button
          className={CSSClass}
          onClick={this.handlePeriod.bind(this, item)}
          key={item.key}>
          {item.name}
        </button>
      );
    });

    const { startDate, endDate } = this.state.customRange;

    return (
      <Appbar.Child className="is-tabs">
        {periodNavigation}
        <div className="dropdown is-right is-hoverable">
          <div className="dropdown-trigger">
            <button
              className={'navbar-item button navbar-button' + (this.isCustomRange ? ' is-active' : '')}
              onClick={this.handleCustomRangeClick.bind(this)}>
              <div>
                Custom Range
                {startDate ? (
                  <div className="date-text">
                    {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
                  </div>
                ) : null}
              </div>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu4" role="menu">
            <div className="dropdown-content">
              <DatePicker
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                onSelectDate={this.handleSelectDate.bind(this)}
              />
            </div>
          </div>
        </div>
      </Appbar.Child>
    );
  }

  handleCustomRangeClick() {
    const { startDate, endDate } = this.state.customRange;
    this.gotoCustomRangeDate(startDate, endDate);
  }

  handleSelectDate(startDate, endDate) {
    this.gotoCustomRangeDate(startDate, endDate);
  }

  handlePeriod(item) {
    this.props.history.push(item.path);
  }

  gotoCustomRangeDate(startDate, endDate) {
    if (startDate && endDate) {
      this.props.history.push(buildPath({
        timeRange: `custom[${
          moment(startDate).format('YYYY-MM-DD')
        },${
          moment(endDate).format('YYYY-MM-DD')
        }]`
      }, this.props));
      this.setState({
        customRange: {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD')
        }
      });
    }
  }

};

export default TimeRange;
