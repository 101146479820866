import React from 'react';
import PropTypes from 'prop-types';

//Components
import Card from './Card';
import CardMark from './Card/CardMark';

import { isInvalidNumber } from 'utils/utilsFn';

const ProductionFlow = (props) => {

var biggestRawProd = 0;
  ((props.productionFlow.length !=[]) ? props.productionFlow : [{totalProduction: 0}]).forEach(item => {
    let sum = 0
    if(item.scrap_units > 0){
      sum += parseFloat(item.scrap_units);
    }
    sum += parseFloat(item.totalProduction);
    
    if(sum > biggestRawProd){
      biggestRawProd = sum;
    }
  });

  const dataSet = (props.productionFlow || []).map((item) => {
    let newItem = item;
    let stoppedTimeItem = (props.stoppedTimeProductionFlow[0])? props.stoppedTimeProductionFlow[0].result.find(e => e.cd_equipment == item.Function) : null; 
    newItem.stopped_time = (stoppedTimeItem)? stoppedTimeItem.stopped_time : 0;
    return newItem;
  });


  let f = 0;
  const flowItems = (dataSet || []).map((item, index, array) => {

    let rawProd = parseFloat(item.totalProduction) + parseFloat(item.scrap_units);

    let aux = index + 1;
    //let s = (index > 0) ? Number(array[index - 1].scrap_units) : 0;

    let pb = (index > 0) ? Number(array[index - 1].totalProduction) : 0;
    let pa = (index < (array.length - 1)) ? Number(array[index + 1].totalProduction) : 0;

    item.scrap = (item.scrap_units / biggestRawProd)*100;

    const tooltip = item.scrap === 100;
    const mark = ((pa === 0 || Number(item.totalProduction) < pa) && array.length > aux);
    //let scrapCount = (Number(item.scrap_units) < 0) ? (Number((item.scrap_units * -1)) + s) * 200 : (Number(item.scrap_units) + s) * 200;

    let ponderedHeight = (200.0*rawProd)/biggestRawProd;

    let valueBorder = (Number(item.scrap) < 100) ? /*scrapCount*/ (item.scrap_units / rawProd)*ponderedHeight/*Number(item.totalProduction)*/ : 0;

    let valueHeight = (f > 200) ? 0 : 200 - f;
    f = (Number(item.scrap_units) > 0) ? f + valueBorder : f;

    
    let classes = '';

    if(valueBorder< 0){
      classes = "negative-scrap";
      ponderedHeight = (200.0*parseFloat(item.totalProduction))/biggestRawProd;
    }

    valueBorder = Math.abs(valueBorder);

    const cssFlow = (Number(item.scrap) < 100)
      ? { height: ponderedHeight/*valueHeight*/, borderTopWidth: (valueBorder > 200) ? ponderedHeight/*valueHeight*/ : valueBorder }
      : { height: 0, borderTopWidth: 0 };


    return (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        <Card cssFlow={cssFlow} classes={classes} {...item} tooltip={tooltip} />
        {(mark)
          ? <CardMark />
          : ''
        }
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      <div className="funnel-component">
        <header className="funnel-component-header">
          <h3 className="title is-3">Production flow</h3>
        </header>
        <div className="funnel-component-body">
          <div className="funnel-component-scroll">
            {flowItems}
          </div>
        </div>
        <footer className="funnel-component-footer" />
      </div>
    </React.Fragment>
  );
};

ProductionFlow.defaultProps = {
  productionFlow: []
};

ProductionFlow.propTypes = {
  productionFlow: PropTypes.array
};

export default ProductionFlow;
