import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { roundNumber, percent } from 'utils/utils';
import { convertToDaysHours } from 'utils/utilsFn';

import { colors } from 'core/constants';

const TimelineBar = (props) => {

  let sum = 0;
  let newData = [];
  var reOrderData = [];

  if(props.data && props.data.unplanned_downtime){

    newData[2] = (props.data.planned_downtime) ? props.data.planned_downtime : {average_duration:0};
    newData[2].nm_downtime_reason = "Planned Downtime"; 
    newData[0] = (props.data.unplanned_downtime) ? props.data.unplanned_downtime : {average_duration:0};
    newData[0].nm_downtime_reason = "Unplanned Downtime";
    newData[1] = (props.data.change_over) ? props.data.change_over : {average_duration:0};
    newData[1].nm_downtime_reason = "Change Over";

    (newData || []).sort((a,b) => {
      return  parseFloat(b.percent) - parseFloat(a.percent)
    }); 

    (newData || []).forEach((item) => sum += item.total_time);
  }else{
    (props.data || []).forEach((item) => sum += item.total_time);
    if(props.data){
      reOrderData = (props.data).sort( (first,second) => {return (second.percent - first.percent)} );
    }
    if ((reOrderData || []).length > 4) {
      (reOrderData || []).forEach((item, index) => {
        if (index < 4) {
          newData[index] = item;
        } else {
          newData[4] = !newData[4] ? {} : newData[4];
          newData[4].nm_downtime_reason = 'Others';
          newData[4].total_time = !newData[4].total_time ? 0 : newData[4].total_time;
          newData[4].total_time += (item.total_time * 1);
          newData[4].reasons = newData[4].reasons || []; 
          newData[4].reasons[index-4] = item //!newData[4].reasons ? [{item}] : newData[4].reasons.push(item);
          
          newData[4].total_occurrences = !newData[4].total_occurrences ? 0 : newData[4].total_occurrences;
          newData[4].total_occurrences += (item.total_occurrences * 1);

          newData[4].average_time = newData[4].total_time/(newData[4].total_occurrences*1.0)
          newData[4].others = true;
        }
      });
    } else {
      newData = (reOrderData || []);
    }
  }

  let dataPercent = '';
  let dataTotal = 0;
  let originalPercent = 0;

  let dataPercentMachine = '';
  let dataTotalMachine = 0;
  let dataPercentReason = 0;
  let dataTotalReason = 0;

  const items = (newData || []).map((item, index) => {
    if (item.total_time > 0) {
      dataPercent = percent(sum, item.total_time, 1);
      originalPercent = dataPercent;
      originalPercent = originalPercent.toString().replace(',', '.');

      if (props.format === 'hours') {
        dataTotal = convertToDaysHours(item.total_time, 'seconds');
      } else if (props.format === 'seconds') dataTotal = dataTotal + ' sec';
      else dataTotal = roundNumber(item.total_time, 0, 1);


      if(props.customTooltip){

        var tooltipComponent;
        var colorIndex = index;

        if (props.customTooltip == "downtimeReasons"){ 
          tooltipComponent = 
            "<h3>"+(item.nm_downtime_reason? item.txt_downtime_reason? item.nm_downtime_reason+" - "+item.txt_downtime_reason:item.nm_downtime_reason:'No name') +"</h3>"+
            "<div><p>Total Time:</p><p>"+ dataTotal+"</p>"+
            "<p>Occurrences:</p><p>"+item.total_occurrences+"</p>"+
            "<p>Average Duration:</p><p>"+ convertToDaysHours(item.average_time, 'seconds')+"</p></div>";
            if(!item.others){
              tooltipComponent +=
              "<table> \
                <thead> \
                  <tr> \
                    <th>Machine</th>\
                    <th>%</th>\
                    <th>Count</th>\
                    <th>Total duration</th>\
                    <th>Avg. duration</th>\
                  </tr> \
                </thead> \
                <tbody>";
                let machineSum = item.total_time;
                let others = {nm_downtime_reason : 'Others', total_duration: 0, occurrences: 0};
                let mLength = item.machines.length;
                (item.machines || []).forEach((item, index) => {

                  if (index < 5) {
                    dataPercentMachine = percent(machineSum, item.total_duration, 1);

                    let avgDuration = convertToDaysHours(item.average_duration, 'seconds');

                    if (props.format === 'hours') {
                      dataTotalMachine = convertToDaysHours(item.total_duration, 'seconds');
                    } else if (props.format === 'seconds') dataTotalMachine = dataTotalMachine + ' sec';
                    else dataTotalMachine = roundNumber(item.total_duration, 0, 1);

                    tooltipComponent += '<tr><td>'+((item.cd_machine) ? item.cd_machine : "Non specified") +'</td><td>'+dataPercentMachine+'%</td><td>'+item.occurrences+'</td><td>'+dataTotalMachine+'</td><td>'+avgDuration+'</td></tr>';
                  } else if(index == mLength-1) {

                    others.total_duration += item.total_duration;
                    others.occurrences += item.occurrences;

                    dataPercentMachine = percent(machineSum, others.total_duration, 1);
                    dataTotalMachine = convertToDaysHours(others.total_duration, 'seconds');

                    let avgDuration = convertToDaysHours(others.total_duration/(others.occurrences*1.0), 'seconds');
  
                    tooltipComponent += '<tr><td>'+others.nm_downtime_reason+'</td><td>'+dataPercentMachine+'%</td><td>'+item.occurrences+'</td><td>'+dataTotalMachine+'</td><td>'+avgDuration+'</td></tr>';
                  
                  }else{
                    others.total_duration += item.total_duration;
                    others.occurrences += item.occurrences;
                  }
              
                });
              tooltipComponent +=
                "</tbody>\
              </table>";
            }else{
            tooltipComponent +=
            "<table> \
              <thead> \
                <tr> \
                  <th>Category</th>\
                  <th>%</th>\
                  <th>Count</th>\
                  <th>Total duration</th>\
                  <th>Avg. duration</th>\
                </tr> \
              </thead> \
              <tbody>";
              let machineSum = item.total_time;
              let others = {nm_downtime_reason : 'Others', total_time: 0, occurrences: 0};
              let mLength = item.reasons.length;
              (item.reasons || []).forEach((item, index) => {

                if (index < 5) {
                  dataPercentMachine = percent(machineSum, item.total_time, 1);

                  let avgDuration = convertToDaysHours(item.average_time, 'seconds');

                  if (props.format === 'hours') {
                    dataTotalMachine = convertToDaysHours(item.total_time, 'seconds');
                  } else if (props.format === 'seconds') dataTotalMachine = dataTotalMachine + ' sec';
                  else dataTotalMachine = roundNumber(item.total_time, 0, 1);

                  tooltipComponent += '<tr><td>'+item.nm_downtime_reason+'</td><td>'+dataPercentMachine+'%</td><td>'+item.total_occurrences+'</td><td>'+dataTotalMachine+'</td><td>'+avgDuration+'</td></tr>';
                } else if(index == mLength-1) {

                  others.total_time += item.total_time;
                  others.occurrences += item.total_occurrences;

                  dataPercentMachine = percent(machineSum, others.total_time, 1);
                  dataTotalMachine = convertToDaysHours(others.total_time, 'seconds');

                  let avgDuration = convertToDaysHours(others.total_time/(others.occurrences*1.0), 'seconds');

                  tooltipComponent += '<tr><td>'+others.nm_downtime_reason+'</td><td>'+dataPercentMachine+'%</td><td>'+others.occurrences+'</td><td>'+dataTotalMachine+'</td><td>'+avgDuration+'</td></tr>';
                
                }else{
                  others.total_time += item.total_time;
                  others.occurrences += item.total_occurrences;
                }
            
              });
            tooltipComponent +=
              "</tbody>\
            </table>";
          }
        }else if(props.customTooltip == "downtimeTypes"){
          tooltipComponent = 
          "<h3>"+(item.nm_downtime_reason || 'No name') +"</h3>"+
          "<div><p>Total Time:</p><p>"+ dataTotal+"</p>"+
          "<p>Occurrences:</p><p>"+item.occurrences+"</p>"+
          "<p>Avg. Duration:</p><p>"+convertToDaysHours(item.average_duration, 'seconds')+"</p></div>"+
            "<table> \
              <thead> \
                <tr> \
                  <th>Category</th>\
                  <th>%</th>\
                  <th>Count</th>\
                  <th>Total duration</th>\
                  <th>Avg. duration</th>\
                </tr> \
              </thead> \
              <tbody>";
              let reasonsSum = item.total_time;
              let reasonsOrdered = (item.list_of_reasons).sort( (first,second) => {return (second.percent - first.percent)} );
              let others = {nm_downtime_reason : 'Others', total_time: 0, total_occurrences: 0};
              (reasonsOrdered || []).forEach((item, index) => {

                if (index < 5) {
                  dataPercentReason = percent(reasonsSum, item.total_time, 1);

                  let avgDuration = convertToDaysHours(item.average_time, 'seconds');

                  if (props.format === 'hours') {
                    dataTotalReason = convertToDaysHours(item.total_time, 'seconds');
                  } else if (props.format === 'seconds') dataTotalReason = dataTotalReason + ' sec';
                  else dataTotalReason = roundNumber(item.total_time, 0, 1);

                  tooltipComponent += '<tr><td>'+item.nm_downtime_reason+'</td><td>'+dataPercentReason+'%</td><td>'+item.total_occurrences+'</td><td>'+dataTotalReason+'</td><td>'+avgDuration+'</td></tr>';
                
                } else if(index == reasonsOrdered.length-1) {
                  others.total_time += item.total_time;
                  others.total_occurrences += item.total_occurrences;
                  dataPercentReason = percent(reasonsSum, others.total_time, 1);
                  dataTotalReason = convertToDaysHours(others.total_time, 'seconds');
                  let avgDuration = convertToDaysHours(others.total_time/(others.total_occurrences*1.0), 'seconds');

                  tooltipComponent += '<tr><td>'+others.nm_downtime_reason+'</td><td>'+dataPercentReason+'%</td><td>'+others.total_occurrences+'</td><td>'+dataTotalReason+'</td><td>'+avgDuration+'</td></tr>';
                }else{
                  others.total_time += item.total_time;
                  others.total_occurrences += item.total_occurrences;
                }
            
              });
            tooltipComponent +=
              "</tbody>\
            </table>";
            colorIndex = colors.secondary.length-1-index;
        
      
        }
        return (
          <div className="timeline-bar-item" style={{ width: originalPercent + '%' }} key={index}>
            <div className="timeline-bar-content" data-html data-tip={tooltipComponent}>
              <div className="timeline-bar-title">{item.nm_downtime_reason || 'no name'}</div>
              <div className="timeline-bar-value" title={dataPercent + '% - ' + dataTotal}>
                {dataPercent}%
              </div>
            </div>
            <div className="timeline-bar-progress" style={{ backgroundColor: colors.secondary[colorIndex] }} />
          </div>
        );
      }else{

        const tooltipComponent = "<p><b>"+(item.name || 'No name') +" - "+dataTotal+"</b></p>";

        return (
          <div className="timeline-bar-item" style={{ width: originalPercent + '%' }} key={index}>
            <div className="timeline-bar-content" data-html data-tip={tooltipComponent}>
              <div className="timeline-bar-title">{item.name || 'no name'}</div>
              <div className="timeline-bar-value" title={dataPercent + '% - ' + dataTotal}>
                {dataPercent}%
              </div>
            </div>
            <div className="timeline-bar-progress" style={{ backgroundColor: colors.secondary[index] }} />
          </div>
        );
      }
    }
  });
  var blankSpaces = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
  return (
    <div className="timeline-bar is-hidden-touch">
    {(props.fetchingKey && props.isFetching[props.fetchingKey])?
      (props.hide)? null : /*<div className={'timeline-loading'}><div className='bar'></div></div>*/
      <div className="timeline-bar-item timeline-loading" style={{ width: '100%' }}>
            <div className="timeline-bar-content">
              <div className="timeline-bar-title">{blankSpaces+blankSpaces+blankSpaces+blankSpaces+blankSpaces}</div>
              <div className="timeline-bar-value">
                {blankSpaces}
              </div>
            </div>
            <div className="timeline-bar-progress relative" style={{ backgroundColor:'#d8d8d8'}}><div className='bar'/></div>
          </div>
    :
      <React.Fragment>
        {/*props.customTooltip? 
          <ReactTooltip className="timeline-tooltip tooltipTable" place="top" type="dark" effect="float" />
        : null*/}
        {items}
      </React.Fragment>
    }
    </div>
  );
};

TimelineBar.defaultProps = {
  data: [],
  format: ''
};

TimelineBar.propTypes = {
  data: PropTypes.array,
  format: PropTypes.string
};

export default TimelineBar;
