export default [{
  id: 1,
  name: 'Usuario',
  email: 'user@packiot.com',
  password: 'cGFja2lvdDIwMTg=',//packiot2018
  profile: {
    id: 1,
    role: 'manager'
  }
}];
