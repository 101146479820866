import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';



//Utils
import { roundNumber, percent, isMobile } from 'utils/utils';
import { formatFloatPercent, getTypeOf, deepKey, filterArrayBy, convertToDaysHours } from 'utils/utilsFn';

import ReactTooltip from 'react-tooltip';
import { isString } from 'util';

export class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      tooltip: false
    };
    this._nodes = new Map();
    this.handleFilter = this.handleFilter.bind(this);
    this.paginator = this.paginator.bind(this);


  }

  static truncData(data){
    let trunced = [];

    (data || []).forEach((item, index) => {
      /*let maxLength = 20;
      let term = (item.Product)?'Product':(item.nm_reason_subcategory)?'txt_reason_subcategory':null;
      if(item[term] && item[term].length > maxLength){
        let name = item[term];
        let minName = name;
        if(item[term].charAt(maxLength-1) == " "){
          minName =name.substr(0,maxLength-1)+"...";
        }else{
          minName = name.substr(0,maxLength)+"...";
        }
        item[term] = [name,minName];

      }else if (isString(item[term])){
        item[term] = [item[term]];
      } */
      trunced.push(item);
    });

    return trunced;
  }



  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.data) !== JSON.stringify(state.items)) {
      return {
        items: (props.data && props.data.orders) ?  DataTable.truncData(props.data.orders) : DataTable.truncData(props.data)
      };
    }
    return null;
  }

  componentDidMount() {
    let data = (this.props.data && this.props.data.orders) ? this.props.data.orders : this.props.data;
    this.paginator(data);
  }

  componentDidUpdate(){
    ReactTooltip.rebuild();
  }

  render() {
    let rows = '';
    let filters = '';
    let options = '';
    let filterCSS = (this.props.isFilterOpen) ? 'is-active' : '';
    let classes = (this.props.classes)? this.props.classes : '';
    var csvHeader = [];



    const parseTableFormats = (value, format, mask) => {
      let formatedValue = value;

      switch (format) {
        case 'string':
          formatedValue = formatedValue || '-';
          break;
        case 'tooltip':
          if(mask == null){
            let maxLength = 20;
            mask = formatedValue;
            if(formatedValue && formatedValue.length > maxLength){
              let name = formatedValue;
              if(formatedValue.charAt(maxLength-1) == " "){
                formatedValue =name.substr(0,maxLength-1)+"...";
              }else{
                formatedValue = name.substr(0,maxLength)+"...";
              }
            }
          }
          formatedValue = formatedValue? <div><p data-tip={mask}>{formatedValue}</p></div>: "-";
          if (!this.state.tooltip) this.setState({tooltip:true});
          break;
        case 'number':
          formatedValue = roundNumber(formatedValue);
          break;
        case 'scrap':
          formatedValue = formatFloatPercent(formatedValue);
          break;
        case 'datetime':
          if (mask === 'seconds') {
            formatedValue = convertToDaysHours(value, 'seconds');
            break;
          }

          if(value !== undefined && value !== null){
            let regexTimeZone = /\+|-..:../;
            if(regexTimeZone.test(value)){
              value = value.slice(0, -6);
            }

          }
          formatedValue = (value == NaN || value == null) ? '-' : (mask == 'HH:mm')
            ? moment.unix(value).utc().format(mask)
            : moment(value).format(mask);
          break;
        case 'status':
          const CSSClass = `span ${
            value === 'Completed' ? 'is-success' : ''
            }${
            value === 'In progress' ? 'is-brand' : ''
            }${
            value === 'Stopped' ? 'is-danger' : ''
            }`;
          formatedValue = <span className={CSSClass}>{value}</span>;
          break;
        default:
          break;
      }
      return formatedValue;
    };

    if (this.props.data) {
      options = Object.keys(this.props.mapKeys || []).map((item, index) => {
        if (item) {
          let keySpan = index + 'option';
          return (
            <th>
              <span
                className="icon order-icon"
                style={{ display: 'inline-block', cursor: 'pointer' }}
                key={keySpan}
                id={index}
                ref={(c) => this._nodes.set(index, c)}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => this.handleFilter(e, index, this.props.mapKeys[item].orderBy)}
                onClick={(e) => this.handleFilter(e, index, this.props.mapKeys[item].orderBy)}>
                <i className="fas fa-angle-up" />
              </span>
            </th>
          );
        } else return '';
      });

      rows = this.state.items.map((item, index) => {
        let keyTr = index;
        let classNameDowntime = '';
        if (this.props.downtime) {
          if (item.hasOwnProperty('End')) {
            classNameDowntime = (item.End.value === null) ? 'datatable-row is-danger' : '';
          }
          if (item.hasOwnProperty('Reason') && classNameDowntime === '') {
            classNameDowntime = (item.Reason === null) ? 'datatable-row is-warning' : '';
          }
        }
        return (
          <tr className={classNameDowntime} key={keyTr + 'tr'}>
            {Object.keys(this.props.mapKeys || item).map((subItem, subIndex) => {
              let keyColunm = subItem;
              let value = item[subItem];
              let format = this.props.mapKeys[subItem].format;

              let mask = null;
              let style = null;

              if(format == 'tooltip'){
                let key = this.props.mapKeys[subItem].mask;
                if(key) mask = item[key];
              }
              if (typeof item[subItem] !== 'object' && item[subItem] && format == 'datetime') {
                format = 'datetime';
                mask = 'DD/MM HH:mm'
              }

              if (typeof item[subItem] === 'object' && item[subItem] && format != 'tooltip') {
                value = item[subItem].value;
                format = 'datetime';
                mask = (subItem == 'Total time') ? 'seconds' : item[subItem].format;
                style = item[subItem].style;
                keyColunm = moment(value).valueOf();
              }

              return (
                <td
                  className="datatable-col"
                  key={keyColunm + 'td'}
                  style={{
                    maxWidth: this.props.mapKeys[subItem] && this.props.mapKeys[subItem].shrink ? 1 : null,
                    minWidth: 80
                  }}>
                  <div className="datatable-col-header">{this.props.mapKeys ? this.props.mapKeys[subItem].name : subItem}</div>
                  <div className="datatable-col-cel"  style={this.props.mapKeys[subItem] ? this.props.mapKeys[subItem].style : null}>
                    {parseTableFormats(value, format, mask)}
                  </div>
                </td>
              );
            })}
          </tr>
        );
      });
    };

    let fetching = this.props.fetchingKey ? this.props.fetchingKey: 'fectchOrders';
    return (
      <React.Fragment>
        <div>
          <table className={"datatable "+classes}>
    {/*this.state.tooltip ?<ReactTooltip className="timeline-tooltip" place="top" type="dark" effect="float" />: null*/}
            <tbody>
              {((this.props.isFetching && !this.props.isFetching[fetching]) && this.props.data && (this.props.data.length == 0  && !this.props.totalProduction || this.props.totalProduction  && this.props.data.orders && this.props.data.orders.length == 0 ))?
                  <tr>
                      <td>
                        <div className="datatable-loading">
                          <span className="datatable-loading-info">{'No data for this period.'}</span>
                        </div>
                      </td>
                    </tr>
                :((this.props.isFetching && this.props.isFetching[fetching]) || (!rows.length && !this.props.error)) ?
                <tr>
                  <td>
                    <div className="datatable-loading">
                      <div className="packiot-loading" />
                      <span className="datatable-loading-info">{'Table is fetching data. If it\'s taking too long please select another period.'}</span>
                    </div>
                  </td>
                </tr>

                :(this.props.error )?
                  <tr>
                    <td>
                      <div className="datatable-loading">
                        <span className="datatable-loading-info">{'Connection time out. Please select a shorter period.'}</span>
                      </div>
                    </td>
                  </tr>
                  : rows
              }
            </tbody>
          </table>
        </div>
        {/* {this.state.totalPages > this.state.perPage ?
          isMobile.any() ?
            <Pagination
              hideFirstLastPages
              prevPageText=""
              nextPageText=""
              activePage={this.state.currentPage}
              itemsCountPerPage={this.state.perPage}
              totalItemsCount={this.state.totalPages}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
            />
            :
            <Pagination
              hideFirstLastPages
              prevPageText="Previous"
              nextPageText="Next page"
              activePage={this.state.currentPage}
              itemsCountPerPage={this.state.perPage}
              totalItemsCount={this.state.totalPages}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
            />
          : null
        } */}
      </React.Fragment>
    );
  }

  handleFilter(e, i, label) {
    let node = this._nodes.get(i).children[0];
    if (node.getAttribute('class') === 'fas fa-angle-up') node.className = 'fas fa-angle-down';
    else node.className = 'fas fa-angle-up';
    this.handleSort(label, this.state.items);
  }

  handleSort(orderBy, items) {
    if (!this.state.filter) {
      items.sort((array1, array2) => {
        if (deepKey(array1, orderBy) > deepKey(array2, orderBy)) return 1;
        if (deepKey(array1, orderBy) < deepKey(array2, orderBy)) return -1;
        return 0;
      });
    } else {
      items.sort((array1, array2) => {
        if (deepKey(array1, orderBy) < deepKey(array2, orderBy)) return 1;
        if (deepKey(array1, orderBy) > deepKey(array2, orderBy)) return -1;
        return 0;
      });
    }
    this.setState({ items: items, filter: !this.state.filter });
  }


  handlePageChange(pageNumber) {
    this.paginator((this.props.data.orders || this.props.data), pageNumber);
  }

  paginator(collection, page = 1) {
    this.setState({
      items: collection
    });
  }
}

DataTable.propTypes = {};

export default DataTable;
