import { request } from 'core/constants';
import { queryCustomRange } from 'utils/utils';
import { promiseWrapper, buildQuery } from 'utils/utilsFn';

export const isFetchingData = (is, type) => (dispatch) => {
  dispatch({
    type: 'IS_FETCHING',
    payload: is,
    dataType: type
  });
};

export const fetchProductionData = (path, query) => (dispatch) => {
  promiseWrapper((resolve, reject, delay) => {
    request.get(`production/${path.component + '/' + path.componentId}/products${buildQuery(query, queryCustomRange)}`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_PRODUCTION_DATA',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_PRODUCTION_DATA_ERROR',
          payload: error
        });
        reject();
      });
  });
};

export const fetchEfficiencyData = (path, query) => (dispatch) => {
  dispatch(isFetchingData(true, 'efficiencyData'));
  return promiseWrapper((resolve, reject, delay) => {
    request.get(`efficiency/${path.component + '/' + path.componentId}${
      buildQuery(query, queryCustomRange)
      }`)
      .then((response) => {
        delay(() => {
          dispatch(isFetchingData(false, 'efficiencyData'));
          dispatch({
            type: 'FETCH_EFFICIENCY_DATA',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch(isFetchingData(false, 'efficiencyData'));
        dispatch({
          type: 'FETCH_EFFICIENCY_DATA_ERROR',
          payload: error
        });
        reject();
      });
  });
};

export const fetchManualvents = (query) => (dispatch) => {
  dispatch(isFetchingData(true, 'events'));
  promiseWrapper((resolve, reject, delay) => {
    request.get(`events/man${buildQuery(query, queryCustomRange)}`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'MANUAL_EVENTS',
            errorRequest: false,
            payload: response.data
          });
          resolve();
          dispatch(isFetchingData(false, 'events'));
        });
      })
      .catch((error) => {
        dispatch({
          type: 'MANUAL_EVENTS',
          errorRequest: true,
          payload: []
        });
        reject();
      });
  });
};

export const fetchOrders = (path, query, customParams) => (dispatch) => {
  customParams = (customParams)? customParams : '';
  dispatch(isFetchingData(true, 'fectchOrders'));
  return (promiseWrapper((resolve, reject, delay) => {
    request.get(`production/${path.component + '/' + path.componentId}/orders${buildQuery(query, queryCustomRange)}`+ customParams)
      .then((response) => {
        delay(() => {
          dispatch(isFetchingData(false, 'fectchOrders'));
          dispatch({
            type: 'FETCH_ORDERS',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch(isFetchingData(false, 'fectchOrders'));
        dispatch({
          type: 'FETCH_ORDERS_ERROR',
          payload: error
        });
        reject();
      });
  })
  )
};

export const fetchClients = () => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {
    request.get('clients')
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_CLIENTS',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_CLIENTS_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchTargets = (path, query) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {
    request.get('targets')
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_TARGETS',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_TARGETS_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchProduction = (path, query) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {
    request.get(`production/${path.component + '/' + path.componentId}${buildQuery(query, queryCustomRange)}`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_PRODUCTION',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_PRODUCTION_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchDowntimeReasons = (path, query,customParams) => (dispatch) => {
  customParams = (customParams)? customParams : '';
  dispatch(isFetchingData(true, 'downtimeReasons'));
  promiseWrapper((resolve, reject, delay) => {
    request.get(`downtime/${path.component + '/' + path.componentId}/reasons${buildQuery(query, queryCustomRange)}`+ customParams)
      .then((response) => {
        delay(() => {
          dispatch(isFetchingData(false, 'downtimeReasons'));
          dispatch({
            type: 'FETCH_DOWNTIME_REASONS',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch(isFetchingData(false, 'downtimeReasons'));
        dispatch({
          type: 'FETCH_DOWNTIME_REASONS_ERROR',
          payload: error
        });
        reject();
      });
  })
};

export const fetchDowntime = (path, query,customParams) => (dispatch) => {
  customParams = (customParams)? customParams : '';
  dispatch(isFetchingData(true, 'downtime'));
  promiseWrapper((resolve, reject, delay) => {
    request.get(`downtime/${path.component + '/' + path.componentId}${buildQuery(query, queryCustomRange)}`+ customParams + '&last=600')
      .then((response) => {
        dispatch(isFetchingData(false, 'downtime'));
        delay(() => {
          dispatch({
            type: 'FETCH_DOWNTIME',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch(isFetchingData(false, 'downtime'));
        dispatch({
          type: 'FETCH_DOWNTIME_ERROR',
          payload: error
        });
        reject();
      });
  })
};

export const fetchScrap = (path, query) => (dispatch) => {
  dispatch(isFetchingData(true, 'scrapDetails'));
  return (
    promiseWrapper((resolve, reject, delay) => {
      let lineFilter = (path) ? '&lineCode=' + path.componentId : '';
      request.get(`production/scrap${buildQuery(query, queryCustomRange)}${lineFilter}`)
        .then((response) => {
          delay(() => {
            dispatch({
              type: 'FETCH_SCRAP',
              payload: response.data
            });
            dispatch(isFetchingData(false, 'scrapDetails'));
            resolve();
          });
        })
        .catch((error) => {
          dispatch({
            type: 'FETCH_SCRAP_ERROR',
            payload: error
          });
          reject();
        });
    })
  );
};

export const getReasons = (id) => (dispatch) => {
  dispatch(isFetchingData(true, 'reasons'));
  promiseWrapper((resolve, reject, delay) => {
    request.get(`downtime/reasons?id_equipment=${id}`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'STOP_REASONS',
            errorRequest: false,
            payload: response.data
          });
          resolve();
          dispatch(isFetchingData(false, 'reasons'));
        });
      })
      .catch((error) => {
        dispatch({
          type: 'STOP_REASONS',
          errorRequest: true,
          payload: []
        });
        reject();
      });
  });
};

let countProductionReq = 0;
export const fetchAllProductionData = (path, query) => (dispatch) => {
  countProductionReq++;
  dispatch(isFetchingData(true, 'all'));
  dispatch(fetchProduction(path, query)).then(() => {
    if (countProductionReq === 1) {
      dispatch(isFetchingData(false, 'production'));
      countProductionReq = 0;
    } else {
      countProductionReq--;
    }
  }, () => countProductionReq--);
};

export const updateData = () => (dispatch) => {
  dispatch({ type: 'UPDATE_DATA' });
};
