import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Components
import Grid from 'components/Grid';
import DataTable from 'lib/DataTable';
import Filter from 'lib/Filter';
import TimelineBar from 'lib/TimelineBar';
import Pagination from 'react-js-pagination';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { CSVLink } from "utils/react-csv";
import moment from 'moment';

import compareParams from 'utils/compareParams';


export class ManualEvents extends React.Component {
  constructor(props) {
    super(props);
    //routerProps(this);
    this.state = {
      filterCss: [],
      isFilterOpen: false,
      suggestions: [],
      data: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  /*getSnapshotBeforeUpdate(prevProps, prevState) {
    if (compareParams(this.props, prevProps) || prevState.aggregationSize !== this.state.aggregationSize) {
      this.fetchAllData(this.props);
    }
    return null;
  }*/

  componentDidMount(){
    if(this.props.sectorList.length > 0){
      const component = (this.props.sectorList || []).find(line => line.name === this.props.match.params.componentId);
      var componentId = (component != undefined)? component.id_equipment+'' : '';
      this.props.fetchManualvents({
        timeRange: this.props.match.params.timeRange,
        line_id: componentId,
        aggregationSize: this.state.aggregationSize
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //if (prevProps.sectorList != this.props.sectorList || (this.props.match.params.componentId != prevProps.match.params.componentId && this.props.sectorList.length > 0)) {
    if (prevProps.sectorList != this.props.sectorList || prevState.aggregationSize !== this.state.aggregationSize || (this.props.sectorList.length > 0 &&
      (this.props.match.params.componentId != prevProps.match.params.componentId  || this.props.match.params.timeRange != prevProps.match.params.timeRange))){

      const component = (this.props.sectorList || []).find(line => line.name === this.props.match.params.componentId);
      var componentId = (component != undefined)? component.id_equipment+'' : '';
      this.props.fetchManualvents({
        timeRange: this.props.match.params.timeRange,
        line_id: componentId,
        aggregationSize: this.state.aggregationSize
      });
    }
    if(JSON.stringify(this.props.manualEvents) != JSON.stringify(prevProps.manualEvents) && this.props.manualEvents.events){
      var sortData = this.props.manualEvents.events.sort((a,b) => moment(b.event_start.value).valueOf() - moment(a.event_start.value).valueOf() );
      this.setState({data: sortData});
    }
  }

  handleClick() {
    if (!this.state.isFilterOpen) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      isFilterOpen: !prevState.isFilterOpen,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node != null && this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }


  render() {
    // Save isFetching prop
    const isFetching = this.props.isFetching;
    const mapKeys = {
      'equipment_cd': {
        name: 'Line',
        format: 'string',
        orderBy: 'equipment_cd'
      },
      'shift': {
        name: 'Shift',
        format: 'number',
        orderBy: 'shift'
      },
      'event_start': {
        name: 'Start',
        orderBy: 'event_start.value',
        format: 'datetime',
        mask: 'DD/MM HH:mm'
      },
      'event_end': {
        name: 'End',
        format: 'datetime',
        mask: 'DD/MM HH:mm'
      },
      'machine_cd': {
        name: 'Machine',
        format: 'string',
        orderBy: 'machine_cd',
      },
      'nm_downtime_reason': {
        name: 'Category',
        format: 'string',
        orderBy: 'nm_downtime_reason'
      },
      'nm_subcategory':{
        name: 'Sub Category',
        format: 'string',
        orderBy: 'nm_subcategory',
      },
      'notes': {
        name: 'Notes',
        format: 'string',
        orderBy: 'notes',},
    };

    let csvHeader = (Object.keys(mapKeys) || []).map((key) => {
      return ({label: mapKeys[key].name, key: key });
    });
    let csvData = ((this.props.manualEvents && this.props.manualEvents.events)? this.props.manualEvents.events : []).map((item) =>{
      return({
        'equipment_cd': item.equipment_cd,
        'shift': item.shift,
        'event_start':  moment(item.event_start.value).format('DD-MM-YYYY HH:mm'),
        'event_end': moment(item.event_end).format('DD-MM-YYYY HH:mm'),
        'machine_cd': item.machine_cd,
        'nm_downtime_reason': item.nm_downtime_reason,
        'nm_subcategory': item.nm_subcategory,
        'notes': item.notes,
      })
    });

    let suggestion = (this.props.clientsList || []).map(item =>{
      return {id: item.client_id,text: item.client_name}
    });
    if(JSON.stringify(suggestion)!=JSON.stringify(this.state.suggestions)){
      this.setState({suggestions: suggestion})
    }
    return (
      <React.Fragment>
        <Grid>
          <Grid.Col className="is-hidden-touch relative">
            <div className="v-space-small" />
            <h3 className="title is-6 is-uppercase">
              Manual Events
            </h3>
            {this.props.manualEvents?
            <CSVLink data={csvData} separator={";"} className="button is-brand-medium-grey is-bare is-medium filter" filename={"manual-events-"+moment().format("YYYY-MM-DD")+".csv"} headers={csvHeader}>
              <Icon name="file-csv" fal={true} />
            </CSVLink>
            :null}
            <hr className="hr is-small" />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col className="datatable-wrapper">
            <DataTable mapKeys={mapKeys}
              data={this.state.data} error={this.props.error} isFetching={this.props.isFetching} fetchingKey={'events'}
            />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  handleAggregationSize({ target }) {
    this.props.updateAggregationSize(target.value);
  }

  fetchFilter(fields){
    setTimeout(function (){
      this.setState({
        isFilterOpen: false,
      });
    }.bind(this), 500);
    var props = this.props;
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId
    };
    this.props.fetchOrders(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    },fields);
  }
}

ManualEvents.defaultProps = {
  isFetching: {},
  fetchOrders: () => {},
  updateAggregationSize: () => { }
};

ManualEvents.propTypes = {
  updateAggregationSize: PropTypes.func,
  fetchOrders: PropTypes.func
};

export default connect((store) => ({
  manualEvents: store.Details.manualEvents,
  isFetching: store.Details.isFetching,
  targets: store.Details.targets,
  productionData: store.Details.productionData,
  error: store.Details.error,
  errorRequest: store.Details.errorRequest,
  clientsList: store.Details.clientsList,
  sectorList: store.app.sectorList
}), actions)(ManualEvents);
