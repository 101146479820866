import React from 'react';
import { roundNumber } from 'utils/utils';

class TreeMapLabel extends React.PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {
    const { width, height } = this.el.getBoundingClientRect();
    this.el.classList.remove('is-off-container');
  }

  componentDidUpdate() {
    const { width, height } = this.el.getBoundingClientRect();
    this.el.classList.remove('is-off-container');
    if (width < 90 || height < 80) this.el.classList.add('is-off-container');
  }

  render() {
    const { name, size } = this.props.value;
    return (
      <span className="treemap-label" ref={(el) => this.el = el}>
        <span className="treemap-label-name">
          {name}
        </span>
        <span className="treemap-label-value">
          <span className="treemap-label-number">{roundNumber(size, true).value}</span>
          <span className="treemap-label-sign">{roundNumber(size, true).token}</span>
        </span>
        <span className="treemap-label-overlaid">
          <span>{name}</span>
        </span>
      </span>
    );
  }
}

export default TreeMapLabel;
