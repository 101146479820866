import { request } from 'core/constants';

import moment from 'moment'

import { parseQuery, queryCustomRange } from 'utils/utils';

import { promiseWrapper, buildQuery, convertDateRange } from 'utils/utilsFn';

export const isFetchingData = (is, type) => (dispatch) => {
  dispatch({
    type: 'IS_FETCHING',
    payload: is,
    dataType: type
  });
};

let inProgress = false;

export const fetchProduction = (path, query) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {

    if (!inProgress) inProgress = true;

    let component = path.component;
    if (path.sector === 'shift') component = 'shifts';
    if (path.sector === 'team') component = 'teams';
    request.get(`production/${component + '/' + path.componentId}${buildQuery(query, queryCustomRange)}`)
      .then((response) => {
        delay(() => {
          if (!inProgress) {
            resolve();
            return;
          }
          inProgress = false;
          dispatch({
            type: 'FETCH_PRODUCTION',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_PRODUCTION_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchOEE = (params) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {
    let body = [];
    params.time_end = 'NOW'
    body.push(params);
    request.post('query/search', body)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_OEE',
            payload: response.data[0].result
          });
          dispatch(isFetchingData(false, 'oee'));
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_OEE_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchEfficiency = (path, query) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {
    request.get(`efficiency/${path.component + '/' + path.componentId}${buildQuery(query, queryCustomRange)}`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_EFFICIENCY',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_EFFICIENCY_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchEvents = (path, query) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {
    request.get(`production/${path.component + '/events'}${buildQuery(query, queryCustomRange)}`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_EVENTS',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_EVENTS_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchCurrentProduction = (path, query) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {
    request.get(`production/${path.component + '/' + path.componentId + '/machines/status'}${
      buildQuery(query, queryCustomRange)
    }`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_CURRENT_PRODUCTION',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_CURRENT_PRODUCTION_ERROR',
          payload: error
        });
        reject();
      });
  })
);

export const fetchProductionFlow = (path, query) => (dispatch) => {
  dispatch(isFetchingData(true, 'productionFlow'));
  return promiseWrapper((resolve, reject, delay) => {
    request.get(`production/${path.component + '/' + path.componentId + '/machines/flow'}${
      buildQuery(query, queryCustomRange)
      }`)
      .then((response) => {
        delay(() => {
          dispatch(isFetchingData(false, 'productionFlow'));
          dispatch({
            type: 'FETCH_PRODUCTION_FLOW',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch(isFetchingData(false, 'productionFlow'));
        dispatch({
          type: 'FETCH_PRODUCTION_FLOW_ERROR',
          payload: error
        });
        reject();
      });
  })
};

export const fetchNewProductionFlow = (path, query) => (dispatch) => {
  let date = convertDateRange(query.timeRange)
  if(path.lineId){
  dispatch(isFetchingData(true, 'stoppedTimeProductionFlow'));
  return promiseWrapper((resolve, reject, delay) => {
    let body = [{query_name: "stop",
                query_type: "machines_stopped_time",
                metric_name: "gross_production",
                id_equipment: path.lineId,
                id_site: path.siteId,
                id_area: path.areaId,
                time_begin: date.time_begin,
                time_end : date.time_end,
                time_grain_size: query.timeRange}];
    request.post(`/query/search`, body)
      .then((response) => {
        delay(() => {
          dispatch(isFetchingData(false, 'stoppedTimeProductionFlow'));
          dispatch({
            type: 'FETCH_STOPPED_TIME_PRODUCTION_FLOW',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch(isFetchingData(false, 'stoppedTimeProductionFlow'));
        dispatch({
          type: 'FETCH_STOPPED_TIME_PRODUCTION_FLOW',
          payload: error
        });
        reject();
      })
      });
    }else{
      return null;
    }
};

export const fetchEfficiencyProgress = (path, query) => (dispatch) => (
  promiseWrapper((resolve, reject, delay) => {

    let component = path.component;
    if (path.sector === 'shift') component = 'shifts/lines';
    if (path.sector === 'team') component = 'teams/lines';
    request.get(`efficiencyprogress/${component + '/' + path.componentId}${buildQuery(query, queryCustomRange)}`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_EFFICIENCYPROGRESS',
            payload: response.data
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_EFFICIENCYPROGRESS_ERROR',
          payload: error
        });
        reject();
      });
  })
);

// TODO: Try to optimize this strategy created to avoid isFetchingData returning false when
// user do a new request before the current request is done
let countProductionReq = 0;
let countEfficiencyReq = 0;
let countEfficiencyProgressReq = 0;

// Combined actions to fetch all data necessary on Total Production, Single Period and Scrap tab
export const fetchAllProductionData = (path, query) => (dispatch) => {
  countProductionReq++;
  countEfficiencyReq++;
  dispatch(isFetchingData(true, 'all'));
  dispatch(fetchProduction(path, query)).then(() => {
    if (countProductionReq === 1) {
      dispatch(isFetchingData(false, 'production'));
      countProductionReq = 0;
    } else {
      countProductionReq--;
    }
  }, () => countProductionReq--);
  dispatch(fetchEfficiency(path, query)).then(() => {
    if (countEfficiencyReq === 1) {
      dispatch(isFetchingData(false, 'efficiency'));
      countEfficiencyReq = 0;
    } else {
      countEfficiencyReq--;
    }
  }, () => countEfficiencyReq--);
  dispatch(fetchEfficiencyProgress(path, query)).then(() => {
    if (countEfficiencyProgressReq === 1) {
      dispatch(isFetchingData(false, 'efficiencyProgress'));
      countEfficiencyProgressReq = 0;
    } else {
      countEfficiencyProgressReq--;
    }
  }, () => countEfficiencyProgressReq--);
};

// Combined actions to fetch all data necessary on Flow Section component
export const fetchFlowSection = (path, query) => (dispatch) => {
  // dispatch(isFetchingData(true, 'all'));
  dispatch(fetchEvents(path, query)).then(() => {
    dispatch(isFetchingData(false, 'events'));
  });
  dispatch(fetchCurrentProduction(path, query)).then(() => {
    dispatch(isFetchingData(false, 'currentProduction'));
  });
  dispatch(fetchProductionFlow(path, query)).then(() => {
    dispatch(isFetchingData(false, 'productionFlow'));
  });

};

export const update = () => (dispatch) => {
  dispatch({ type: 'UPDATE' });
};
