import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Components
import LoadingContainer from 'components/LoadingContainer';
import Grid from 'components/Grid';
import SingleLineChart from 'lib/dataComponents/SingleLineChart';
import AggregationSizeSelector from 'lib/AggregationSizeSelector';
//import ScrapAndEfficiency from '../components/ScrapAndEfficiency';
import Oee from '../components/OEE';

// Utils
import RouterUtils from 'utils/RouterUtils';

// Constants
import { colors } from 'core/constants';

import Icon from 'components/Icon';
import { CSVLink } from "utils/react-csv";

import moment from 'moment';

import { hashCode, isInvalidNumber, fixNumber } from '../../../../utils/utilsFn';

export class TotalProduction extends React.PureComponent {

  render() {

    // Save isFetching prop
    const isFetching = this.props.isFetching;

    // Set all the constants necessary to hide or show Production Flow, Events and Current Production
    const isDay = RouterUtils.constant('isDay');
    const isAllLines = RouterUtils.constant('isAllLines');
    const isOneLine = RouterUtils.constant('isOneLine');

    // Show or hide Production Flow, Events and Current Production
    const showFlowSection = isDay && (isAllLines || isOneLine);

    const isShift = this.props.match.params.sector === 'shift';
    const isTeam = this.props.match.params.sector === 'team';

    let legendKeys = [{
      label: 'Amount Produced',
      color: colors.primary.blue
    }, {
      label: 'Trend Line',
      color: colors.primary.grey
    }, {
      label: 'Production Goal',
      color: colors.primary.green
    }];

    let csvHeader = [
      {label: 'Date', key: 'timestamp' },
      {label: 'Production', key: 'countTotal' },
      {label: 'Scrap (%)', key: 'scrap' },
      {label: 'Target', key: 'singlePeriodTarget' },
    ];

    let chartArray = [...this.props.production];
    if (isShift && this.props.production.length > 0 && this.props.production[0] && this.props.production[0].shifts) {

      legendKeys = [];
      chartArray[0].shifts.forEach((item, index) => {
        legendKeys.push({
          label: 'Shift ' + item.shift,
          color: colors.secondary[index]
        });
      });

      let csvHeaderShift = [{label: 'Date', key: 'timestamp'}];

      this.props.production[0].shifts.forEach((shift) => {
      
        csvHeaderShift.push({label: ("Shift "+shift.shift+" Production"), key: ('shift'+shift.shift+'CountTotal')});
        csvHeaderShift.push({label: ("Shift "+shift.shift+" Scrap (%)"), key: ('shift'+shift.shift+'Scrap')});
        csvHeaderShift.push({label: ("Shift "+shift.shift+" Target"), key: ('shift'+shift.shift+'SinglePeriodTarget')});
      });

      csvHeader = csvHeaderShift;

      

    }else if (isTeam && this.props.production.length > 0 && this.props.production[0] && this.props.production[0].teams) {

      legendKeys = [];
      chartArray[0].teams.forEach((item, index) => {
        legendKeys.push({
          label: 'Team ' + item.team,
          color: colors.secondary[index]
        });
      });

      let csvHeaderTeam = [{label: 'Date', key: 'timestamp'}];

      this.props.production[0].teams.forEach((team) => {
      
        csvHeaderTeam.push({label: ("Team "+team.team+" Production"), key: ('team'+team.team+'CountTotal')});
        csvHeaderTeam.push({label: ("Team "+team.team+" Scrap (%)"), key: ('team'+team.team+'Scrap')});
        csvHeaderTeam.push({label: ("Team "+team.team+" Target"), key: ('team'+team.team+'SinglePeriodTarget')});
      });

      csvHeader = csvHeaderTeam;
    }
    
  
    let csvData = (this.props.production || []).map((item, index) =>{
      if(isShift){
        let res = [];
        res['timestamp'] = item.timestamp;
        if(item.shifts){
          item.shifts.forEach((shift) => {

            let percentScrap = (!isInvalidNumber(shift.scrap)) ? fixNumber(((shift.scrapacum / (shift.acumTotal + shift.scrapacum)) * 100), 2, 'pt-BR') : '';

            res['shift'+shift.shift+'CountTotal'] = shift.countTotal;
            res['shift'+shift.shift+'Scrap'] = percentScrap;
            res['shift'+shift.shift+'SinglePeriodTarget'] = Math.round(parseFloat(''+shift.singlePeriodTarget));
          });
        }

        return Object.assign({}, res);

      }else if(isTeam){

        let res = [];
        res['timestamp'] = item.timestamp;
        if(item.teams){
          item.teams.forEach((team) => {

            let percentScrap = (!isInvalidNumber(team.scrap)) ? fixNumber(((team.scrapacum / (team.acumTotal + team.scrapacum)) * 100), 2, 'pt-BR') : '';

            res['team'+team.team+'CountTotal'] = team.countTotal;
            res['team'+team.team+'Scrap'] = percentScrap;
            res['team'+team.team+'SinglePeriodTarget'] = Math.round(parseFloat(''+team.singlePeriodTarget));
          });
        }

        return Object.assign({}, res);

      }

      let percentScrap = (!isInvalidNumber(item.scrap)) ? fixNumber(((item.scrapacum / (item.acumTotal + item.scrapacum)) * 100), 2, 'pt-BR') : '';
      
      
      return({
        'timestamp': item.timestamp,
        'countTotal': item.countTotal,
        'scrap': percentScrap,
        'singlePeriodTarget': Math.round(item.singlePeriodTarget),
      })
    });

    csvData.shift();

    return (
      <React.Fragment>

        
      <div className='has-text-right'>
        <AggregationSizeSelector
          current={this.props.aggregationSize}
          onChange={this.handleAggregationSize.bind(this)}
          classes='p-b-0 is-inline-flex'
        />

        {this.props.production?
        <div className='charts is-inline-flex'>
          <CSVLink data={csvData} headers={csvHeader} separator={";"} className="button is-brand-medium-grey is-bare is-medium" filename={"total-production-"+moment().format("YYYY-MM-DD")+".csv"} >
            <Icon name="file-csv" fal={true} />
          </CSVLink>
        </div>
        :null}
        </div>

        <Grid>
          <Grid.Col>

            <LoadingContainer isActive={isFetching.production}>
              <SingleLineChart
                chartData={chartArray}
                legendKeys={legendKeys}
                isMultiline={isShift || isTeam}
                isTeam={isTeam}
              />
            </LoadingContainer>

          </Grid.Col>
        </Grid>

        {/* {!isShift ? <ScrapAndEfficiency {...this.props}/> : null} */}
        {!isShift && !isTeam ? <Oee {...this.props} /> : null}

        {/* {showFlowSection && !isShift ? <FlowSection {...this.props} {...RouterUtils} /> : null} */}

      </React.Fragment>
    );
  }

  handleAggregationSize({ target }) {
    this.props.updateAggregationSize(target.value);
  }

}

TotalProduction.defaultProps = {
  isFetching: {},
  updateAggregationSize: () => {}
};

TotalProduction.propTypes = {
  production: PropTypes.array.isRequired
};

export default connect((store) => ({
  production: store.Dashboard.production,
  efficiency: store.Dashboard.efficiency,
  oee: store.Dashboard.oee,
  events: store.Dashboard.events,
  currentProduction: store.Dashboard.currentProduction,
  productionFlow: store.Dashboard.productionFlow,
  isFetching: store.Dashboard.isFetching
}), actions)(TotalProduction);
