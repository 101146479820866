import { request } from 'core/constants';

import { promiseWrapper } from 'utils/utilsFn';


export const isFetchingData = (is, type) => (dispatch) => {
  dispatch({
    type: 'IS_FETCHING',
    payload: is,
    dataType: type
  });
};

export const fetchTargets = (path, query) => (dispatch) => (
  request.get('targets')
    .then((response) => {
      dispatch({
        type: 'FETCH_TARGETS',
        payload: response.data
      });
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_TARGETS_ERROR',
        payload: error
      });
    })
);

export const saveTarget = (body) => (dispatch) => {
  request.post('targets', body)
    .then((response) => {
      dispatch({
        type: 'SAVE_TARGETS_DATA',
        payload: response.data
      });
      dispatch({
        type: 'UPDATE_TARGETS_DATA',
        payload: body
      });
    })
    .catch((error) => {
      dispatch({
        type: 'SAVE_TARGETS_DATA_ERROR',
        payload: error
      });
    });
};

export const fetchDataCSV = (path, query) => (dispatch) => {
  dispatch(isFetchingData(true, 'csvUpload'));
  return request.get('orders/csvImport')
    .then((response) => {
      dispatch(isFetchingData(false, 'csvUpload'));
      dispatch({
        type: 'FETCH_DATACSV',
        payload: response.data
      });
    })
    .catch((error) => {
      dispatch(isFetchingData(false, 'csvUpload'));
      dispatch({
        type: 'FETCH_DATACSV_ERROR',
        payload: error
      });
    })
  };

export const fetchGoalPeriod = (period, idEquipment) => (dispatch) => {
  dispatch(isFetchingData(true, 'customTarget'));
  return request.get('targets/'+period+'/equipment/'+idEquipment)
    .then((response) => {
      dispatch(isFetchingData(false, 'customTarget'));
      dispatch({
        type: 'FETCH_DATA_GOAL_PERIOD',
        payload: response.data
      });
    })
    .catch((error) => {
      dispatch(isFetchingData(false, 'customTarget'));
      dispatch({
        type: 'FETCH_DATA_GOAL_PERIOD_ERROR',
        payload: error
      });
    })
};

export const updateGoalData = (period, idEquipment, body) => (dispatch) => {
  return request.post('targets/'+period+'/equipment/'+idEquipment, body)
    .then((response) => {
      dispatch({
        type: 'UPDATE_GOAL_DATA',
        payload: body
      });
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_GOAL_DATA_ERROR',
        payload: error
      });
    });
};
export const deleteGoalData = (period, body) => (dispatch) => {
  return request.delete('targets/'+period, {data:body})
    .then((response) => {
      dispatch({
        type: 'UPDATE_GOAL_DATA',
        payload: body
      });
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_GOAL_DATA_ERROR',
        payload: error
      });
    });
};
export const saveDataCSV = (body, config) => (dispatch) => {

    return promiseWrapper((resolve, reject, delay) => {
      dispatch(isFetchingData(true, 'csvUpload'));
      request.post('orders/csvImport', body)
        .then((response) => {
          dispatch(isFetchingData(false, 'csvUpload'));
          delay(() => {
            dispatch({
              type: 'SAVE_DATACSV',
              payload: response.data
            });
            dispatch({
              type: 'UPDATE_DATACSV',
              payload: body
            });
            resolve();
            
          });
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: 'SAVE_DATACSV_ERROR',
            payload: error
          });
          dispatch(isFetchingData(false, 'csvUpload'));
          reject();
        });
    });
};