import { request } from 'core/constants';
import users from 'utils/mocks/userList';

import { promiseWrapper } from 'utils/utilsFn';

export const fetchTargets = () => (dispatch) => {
  request.get('targets')
    .then((response) => {
      dispatch({
        type: 'APP_FETCH_TARGETS',
        payload: response.data
      });
    })
    .catch(() => {
      dispatch({
        type: 'APP_FETCH_TARGETS_ERROR',
        payload: []
      });
    });
};

export const fetchSectorList = () => (dispatch) => {
  request.get('lines')
    .then((response) => {
      dispatch({
        type: 'APP_FETCH_SECTOR_LIST',
        payload: response.data
      });
    })
    .catch(() => {
      dispatch({
        type: 'APP_FETCH_SECTOR_LIST_ERROR',
        payload: []
      });
    });
};

export const fetchSitesList = () => (dispatch) => {
  request.get('sites')
    .then((response) => {
      dispatch({
        type: 'APP_FETCH_SITES_LIST',
        payload: response.data
      });
    })
    .catch((error) => {
      dispatch({
        type: 'APP_FETCH_SITES_LIST',
        payload: error
      });
    });
};

export const fetchEnterpriseInfo = () => (dispatch) => {
  request.get('enterprise/info')
    .then((response) => {
      dispatch({
        type: 'APP_FETCH_ENTERPRISE_INFO',
        payload: response.data
      });
    })
    .catch(() => {
      dispatch({
        type: 'APP_FETCH_ENTERPRISE_INFO_ERROR',
        payload: []
      });
    });
};

export const checkSession = (companyId) => (dispatch, store) => {
  return new Promise((resolve) => {
    if (!localStorage.getItem('packiot-jwt') || !Number.isInteger(parseInt(companyId))) {
      localStorage.removeItem('packiot-jwt');
      localStorage.removeItem('packiot-jwt-uid');
      dispatch({ type: 'APP_DESTROY_SESSION' });
      return;
    }
    if (!store().app.loggedUser) {
      // Change this in the future to an API request
      dispatch({
        type: 'APP_SET_USER',
        payload: users.filter((item) => item.id == companyId)[0]
      });
    }
    resolve();
  });
};
export const getUserPermissions = () => (dispatch) => {
  return promiseWrapper((resolve, reject, delay) => {
  
    request.get('me')
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'USER_PERMISSIONS_DATA',
            errorRequest: false,
            payload: response.data
          });
          resolve();
          dispatch(isFetchingData(false, 'me'));
        });
      })
      .catch((error) => {
        dispatch({
          type: 'USER_PERMISSIONS_DATA_ERROR',
          errorRequest: true,
          payload: []
        });
        reject();
      });
  });
};
