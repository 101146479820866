import React from 'react';

// Components
import Button from 'components/Button';
import Icon from 'components/Icon';
import Appbar from 'components/Appbar';

// Assets
import packiotLogo from 'static/media/packiot-dark.svg';
import TimeRange from './TimeRange';
import Sector from './Sector';

const MainHeader = (props) => (
  <Appbar>

    <Appbar.Child className={'header-logo'}>
      <figure
        onClick={props.gotoHome.bind(this)}
        onKeyPress={props.gotoHome.bind(this)}
        role="button">
        <img src={packiotLogo} alt="PackIoT" width="120" />
      </figure>

    </Appbar.Child>

    <Appbar.Child grow>
      <Sector {...props} />
    </Appbar.Child>

    <Appbar.Child grow right>
      {props.canEdit?
      <Button specSelector="settings" theme="brand-medium-grey" onClick={props.onClickSettings} bare medium>
        <Icon name="cogs" />
      </Button>
      :null}
      <Button specSelector="logout" theme="brand-medium-grey" onClick={props.onClickLogout} bare medium>
        <Icon name="sign-out" />
      </Button>
    </Appbar.Child>

    <TimeRange {...props} />

  </Appbar>
);

export default MainHeader;
