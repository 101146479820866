import React from 'react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import Grid from 'components/Grid';
import Components from 'lib/Components';
import ViewTitle from 'lib/ViewTitle';
import ViewSelector from 'lib/ViewSelector';
import Tabs from 'lib/Tabs';
import Icon from 'components/Icon';
import Button from 'components/Button';

// Utils
import buildPath from 'utils/buildPath';
import RouterUtils from 'utils/RouterUtils';

const AnalyticsHOC = (Component, tabList = []) => {

  class Analytics extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        isFilterOpen:false,
        refreshCount: 0,
      };

      this.limitLineAcess();
    }

    render() {

      const params = RouterUtils.params;

      return (
        <React.Fragment key={this.state.refreshCount}>

          <div className="v-space-small" />

          <Components
            items={this.props.sectorList}
            activeKey={params.componentId}
            onSelect={this.handleSectorSelection.bind(this)}>
            <ViewSelector
              activeKey={params.view}
              onRefresh={this.handleRefresh.bind(this)}
              onSelect={this.handleSelectView.bind(this)}
            />
          </Components>
          <div className="app-footer-credits update-time">Last updated {(this.props.updateTime)?this.props.updateTime:''}</div>

          <ViewTitle urlParams={{ ...params }} />

          <Grid>
            <Grid.Col>
              <Tabs items={tabList} activeSector={params.sector} activeKey={params.tab} onSelect={this.handleTabSelection.bind(this)} />
            </Grid.Col>
          </Grid>

          <Component
            {...this.state}
            {...this.props}
          />

        </React.Fragment>
      );
    }

    handleRefresh() {
      this.setState({refreshCount: this.state.refreshCount+1});
    }

    handleSelectView(view) {
      this.props.history.push(buildPath({
        view: view.key,
        tab: view.homeTab
      }, this.props));
    }

    handleSectorSelection(component) {
      const params = RouterUtils.params;
      this.props.history.push(buildPath({ ...params, ...{ componentId: component.id } }, this.props));
    }

    handleTabSelection(tab) {
      const params = RouterUtils.params;
      this.props.history.push(buildPath({ ...params, ...{ tab: tab.key } }, this.props));
    }

    refreshData() {
      this.props.updateData();
    }

    componentDidMount() {
      this.limitLineAcess();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      this.limitLineAcess();
    }

    limitLineAcess(){
      let productionFlow = {
        name: 'Production Flow',
        key: 'production-flow',
        shift: true,
        team: true,
      };
      if(this.props.name == 'Dashboard'){
        if(this.props.match.params.componentId == 'ALL'){
          //( tabList[4] != undefined && tabList[4].key == "production-flow" )? (tabList.pop() && this.forceUpdate()) : null;
          tabList[3].team = true;
          if(!tabList[3].shift){
            tabList[3].shift = true;
            this.forceUpdate();
          }
        }else{
        // ( tabList.length <= 4 )? (tabList.push(productionFlow) && this.forceUpdate()) : null;
          tabList[3].team = false;
          if(tabList[3].shift){
            tabList[3].shift = false;
            this.forceUpdate();
          }
        }
      }
    }

  };

  Analytics.defaultProps = {
    match: { params: {} }
  };

  function mapStateToProps(store) {
    return {
      sectorList: store.app.sectorList,
      sitesList: store.app.sitesList,
      targets: store.app.targets,
      updateTime: store.app.updateTime
    };
  }

  function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({}, dispatch) };
  }

  return connect(mapStateToProps, mapDispatchToProps)(Analytics);

};

export default AnalyticsHOC;
