import React from 'react';

const ChartLegend = (props) => {
  const { legendKeys } = props;
  return (
    <React.Fragment>
      <ul style={{ textAlign: 'center' }} className="chart-legend">
        {legendKeys.map((entry, index) => {
          let classes = "chart-legend-icon";
          let colors = entry.color
          if(!entry.type){
            return(
            <li key={index} className="chart-legend-item">
              <span className={classes} style={{ backgroundColor: colors }} />
              {entry.label}
            </li>);
          }
        })}
      </ul>
      <ul style={{ textAlign: 'center' }} className="chart-legend">
        {legendKeys.map((entry, index) => {
          let classes = "chart-legend-icon";
          let colors = entry.color
          if(entry.type){

            classes = "chart-legend-"+entry.type;
            colors = ''

            return(
              <li key={index} className="chart-legend-item">
                <span className={classes} style={{ backgroundColor: colors }} />
                {entry.label}
              </li>);
          }
        })}
        </ul>
    </React.Fragment>
  );
};

export default ChartLegend;
