import React from 'react';

// Components
import Appbar from 'components/Appbar';

// Utils
import buildPath from 'utils/buildPath';

const Sector = (props) => {

   var sectors = [{
    name: 'Factory',
    key: 'factory',
    path: buildPath({ sector: 'factory' }, props)
  }, {
    name: 'Shift',
    key: 'shift',
    path: buildPath({ sector: 'shift' }, props)
  }];

  (sectors.length <= 2 && props.enterpriseInfo.teams == 1)? sectors.push({
    name: 'Team',
    key: 'team',
    path: buildPath({ sector: 'team' }, props)
  }) : null;

  const sectorNavigation = sectors.map((item) => {
    const isActive = item.key === props.match.params.sector;
    const CSSClass = `navbar-item button navbar-button ${ isActive ? 'is-active' : '' }`;
    return (
      <button
        className={CSSClass}
        onClick={() => props.history.push(item.path)}
        key={item.key}>
        {item.name}
      </button>
    );
  });

  return (
    <Appbar.Child grow>
      {sectorNavigation}
    </Appbar.Child>
  );

};

export default Sector;
