import React from 'react';

import XTick from './components/XTick';
import YTick from './components/YTick';
import ChartLegend from './components/ChartLegend';
import ChartTooltip from './components/ChartTooltip';
import StaticTooltip from './components/StaticTooltip';

// Constants
import { colors } from 'core/constants';
// Utils
import { convertHex } from 'utils/utilsFn';

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { hashCode } from '../../utils/utilsFn';

const Lines = (props) => {

  const isTeam = props.isTeam;

  // Create new array to avoid affect the original
  let chartData = props.chartData.concat();
  let multilineChartData = [];


  if(isTeam){
    chartData = chartData.map((item) => {
      if (!item.incomplete) item.acumTotalComplete = item.acumTotal;
      //item.teams = (item.teams || []).sort((a,b) => (a.team > b.team) ? 1 : ((b.team > a.team) ? -1 : 0));
      if (props.isMultiline) {
        item.teams ? item.teams.forEach((shift, index) => {
          if (shift.acumTotal !== null && !shift.incomplete) shift.acumTotal = shift.acumTotal * 1;
          item['line' + shift.team] = shift;
        }) : null;
        if(item.teams){
          multilineChartData = (multilineChartData.length < item.teams.length )? item.teams :multilineChartData;
        }
      }
      return item;
    });

  }else{
    // Apply a new value in all items without the "incomplete" flag
    chartData = chartData.map((item) => {
      if (!item.incomplete) item.acumTotalComplete = item.acumTotal;
      if (props.isMultiline) {
        //item.shifts = (item.shifts || []).sort((a,b) => (a.shift > b.shift) ? 1 : ((b.shift > a.shift) ? -1 : 0));
        item.shifts ? item.shifts.forEach((shift, index) => {
          if (shift.acumTotal !== null && !shift.incomplete) shift.acumTotal = shift.acumTotal * 1;
          item['line' + shift.shift] = shift;
        }) : null;

        if(item.shifts){
          multilineChartData = (multilineChartData.length < item.shifts.length )? item.shifts :multilineChartData;
        }
      }
      return item;
    });
  }

  let mainLinesShifts = null;
  let mainLines = (
    <Line
      isAnimationActive={false}
      type="linear"
      yAxisId='left'
      dataKey="acumTotal"
      stroke="rgba(57, 159, 239, .6)"
      strokeWidth="4"
      dot={false}
      activeDot={activeDotStyle}
    />
  );

  if (props.isMultiline) {


    let lines = [];
    let linesIncomplete = [];
    (multilineChartData && multilineChartData.length > 0) ? (multilineChartData).forEach((shift, index) => {
      if(isTeam){
        lines.push(['line' + shift.team, shift.team]);
      }else{
        lines.push(['line' + shift.shift, shift.shift]);
      }
    }) : null;
    (multilineChartData && multilineChartData.length > 0) ? (multilineChartData).forEach((shift, index) => {
      let incomplete = Object.keys(shift).some(() => shift && shift['incomplete'] || false);

      if(isTeam){
        linesIncomplete.push(['line' + shift.team, shift.team]);
      }else{
        linesIncomplete.push(['line' + shift.shift, shift.shift]);;
      }
    }) : null;
    mainLines = lines.map((line, index) => (

      <Line
        key={line[0]}
        isAnimationActive={false}
        type="linear"
        dataKey={line[0] + '.acumTotal'}
        stroke={colors.secondary[index]}
        strokeWidth="4"
        yAxisId='left'
        dot={false}
        activeDot={{ stroke: 'white', fill: colors.secondary[index], strokeWidth: 3, r: 9 }}
      />
    ));

    mainLinesShifts = lines.map((line, index) => {
      const rgba = convertHex(colors.secondary[index], 60);
      return (
        <Line
          key={line[0]}
          isAnimationActive={false}
          type="linear"
          dataKey={line[0] + '.acumTotal'}
          stroke={rgba}
          yAxisId='left'
          strokeWidth="4"
          dot={false}
          activeDot={{ stroke: 'white', fill: colors.secondary[index], strokeWidth: 3, r: 9 }}
        />
      );
    }
    );
  }

  const chartSettings = { top: /*2*/0, right: /*40*/14, left: /*30*/14, bottom: /*2*/0 };
  const activeDotStyle = { stroke: '#FFF', fill: colors.primary.blue, strokeWidth: 3, r: 9 };

  let tooltipCards = ['toGoalOnTotalProduction', 'scrapOnTotalProduction', 'totalProduction', 'periodProduction'];
  if (props.isMultiline) tooltipCards = ['shiftsOnTotalProduction'];
  let lastPoint = chartData[chartData.length-1];
  var dataMax = (lastPoint) ? ((lastPoint.target != null && lastPoint.acumTotal != null && parseFloat(lastPoint.target) > parseFloat(lastPoint.acumTotal)) || lastPoint.target != null)? parseFloat(lastPoint.target) : parseFloat(lastPoint.acumTotal): 'auto';

  if (props.isMultiline) dataMax = 'auto';
  let intervalData = 'preserveStart';
  if(chartData.length < 10) intervalData = 0;
  if(chartData.length > 10 && chartData.length <= 33) intervalData = 1;

  return (
    <div className="axis-chart has-panel-overlaid">
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={chartData} margin={chartSettings}>
          <CartesianGrid strokeDasharray="2 3" />

          <XAxis
            dataKey="timestamp"
            tick={<XTick hideFirstTick isMultiline={props.isMultiline} />}
            interval={intervalData}
          />

          <YAxis
            label={{ position: 'insideLeft' }}
            tick={<YTick />}
            domain={[0, dataMax]}
            yAxisId='left'
            interval={'preserveStartEnd'}
          />

          <Tooltip
            content={(
              <ChartTooltip
                chartData={chartData}
                cards={tooltipCards}
                isMultiline={props.isMultiline}
              />
            )}
          />

          <Line
            isAnimationActive={false}
            type="linear"
            dataKey="linregress"
            yAxisId='left'
            stroke={colors.primary.grey}
            strokeDasharray="2 3"
            strokeWidth="2"
            dot={false}
            activeDot={false}
          />

          <Line
            isAnimationActive={false}
            type="linear"
            dataKey="target"
            yAxisId='left'
            stroke={colors.primary.green}
            strokeWidth="2"
            dot={false}
            activeDot={false}
          />

          {mainLines}
          {mainLinesShifts}

          <Line
            isAnimationActive={false}
            type="linear"
            dataKey="acumTotalComplete"
            yAxisId='left'
            stroke={colors.primary.blue}
            strokeWidth="4"
            dot={false}
            activeDot={activeDotStyle}
          />

          <Legend
            content={<ChartLegend />}
            legendKeys={props.legendKeys}
          />

        </LineChart>
      </ResponsiveContainer>

      {!props.isMultiline ? <StaticTooltip chartData={chartData} /> : null}
    </div>
  );
};

Lines.defaultProps = {
  chartData: [],
  legendKeys: []
};

export default Lines;
