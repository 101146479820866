import React from 'react';
import ReactDOM from 'react-dom';

import 'components/_all.scss'; // All components styles

import App from 'core/App';

console.log('Ahoy!');

ReactDOM.render(<App />, document.getElementById('root'));
