import React from 'react';

// Redux
import { connect } from 'react-redux';
import * as actions from './actions';

// Components
import AnalyticsHOC from 'containers/Analytics';

// Utils
import ChildRoutes from 'utils/ChildRoutes';
import compareParams from 'utils/compareParams';
import RouterUtils from 'utils/RouterUtils';

// Misc
var tabList = [{
  name: 'Total Production',
  key: 'total-production',
  shift: false,
  team: false
}, {
  name: 'Single Period',
  key: 'single-period',
  shift: false,
  team: false
}, {
  name: 'Scrap',
  key: 'scrap',
  shift: false,
  team: false
}, {
  name: 'OEE Progress',
  key: 'oee-progress',
  shift: false,
  team: false,
}];

export class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      aggregationSize: 'day'
    };
  }

  componentDidMount() {
    this.fetchAllData(this.props);
    if(this.props.sitesList) {
      this.fetchOeeData();
    }

  }


  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.match.params.sector   !=  prevProps.match.params.sector   ||
        this.props.match.params.componentId !=  prevProps.match.params.componentId ||
        prevState.aggregationSize           !== this.state.aggregationSize ||
        this.props.match.params.componentId != prevProps.match.params.componentId ||
        this.props.match.params.timeRange   !=  prevProps.match.params.timeRange) {

      this.fetchAllData(this.props);
      this.fetchOeeData();
    }
    if(this.props.sitesList != prevProps.sitesList ) {
      this.fetchOeeData();
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <ChildRoutes
          {...this.props}
          passPropsToRoute={
            { ...this.state, updateAggregationSize: this.updateAggregationSize.bind(this), sectorList: this.props.sectorList, sitesList: this.props.sitesList }
          }
        />
      </React.Fragment>
    );
  }

  getParamsToSearch() {
    let { timeRange, aggregationSize, componentId } = this.props.match.params;
    if(this.props.sitesList){
      const idSite = this.props.sitesList[0].id_site;
      let data = {
        id_resource: '',
        id_site: idSite,
        query_name: 'CALC_OEE',
        metric_name: 'oee',
        query_type: 'metric',
        time_begin: '',
        time_end: 'END',
        resource_type: 'site',
        time_grain_size: aggregationSize || 'DAY'
      };

      let idLine = null;
      let lines = this.props.sitesList[0].areas[0].lines
      for(let i of lines){
        if(i.nm_line === componentId){
          idLine = i.id_line;
        }
      }
      if(idLine == null){
        data.id_resource = idSite;
      }else{
        data.resource_type = "line";
        data.id_resource = idLine;
      }

      switch (timeRange) {
      case 'day':
        data.time_begin = 'DAY';
        data.time_grain_size = 'HOUR';
        break;
      case 'week':
        data.time_begin = 'WEEK';
        data.time_grain_size = 'DAY';
        break;
      case 'month':
        data.time_begin = 'MONTH';
        break;
      default:
        let startDate = timeRange.split(',')[0].replace('custom[', '');
        let endDate = timeRange.split(',')[1].replace(']', '');
        startDate += ' 00:00:00';
        endDate += ' 23:59:00';

        data.time_begin = startDate;
        data.time_end = endDate;
        break;
      }
      return data;
    }
    return null;
  }

  fetchOeeData() {
    let params = this.getParamsToSearch();
    this.props.fetchOEE(params);
  }

  fetchData(props, path){
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId,
      sector: nextParams.sector
    };

    if(path == "total-production"){
      this.props.fetchEfficiencyProgress(mainParams, {
        timeRange: nextParams.timeRange,
        aggregationSize: this.state.aggregationSize
      });

      //oee
      this.props.fetchOEE(mainParams, {
        timeRange: nextParams.timeRange,
        aggregationSize: this.state.aggregationSize
      });


    }else if(path == "single-period"){
      this.fetchEfficiencyProgressData(this.props);


    }else if(path == "scrap"){
      this.fetchEfficiencyProgressData(this.props);


    }else if(path == "efficiency-progress"){
      this.props.fetchEfficiencyProgress(mainParams, {
        timeRange: nextParams.timeRange,
        aggregationSize: this.state.aggregationSize
      });

    }else{
      this.fetchAllData(this.props);
    }
  }

  fetchAllData(props) {


    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId,
      sector: nextParams.sector
    };
    this.props.fetchAllProductionData(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    /*this.props.fetchFlowSection(mainParams, {
      timeRange: nextParams.timeRange
    });*/
  }

  updateAggregationSize(value) {
    RouterUtils.setConstant('aggregationSize', value);
    this.setState({
      aggregationSize: value
    });
  }

}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default AnalyticsHOC(connect((store) => ({
  production: store.Dashboard.production,
  efficiency: store.Dashboard.efficiency,
  efficiencyProgress: store.Dashboard.efficiencyProgress,
  events: store.Dashboard.events,
  currentProduction: store.Dashboard.currentProduction,
  productionFlow: store.Dashboard.productionFlow,
  isFetching: store.Dashboard.isFetching
}), actions)(Dashboard), tabList);
