import React from 'react';

import { roundNumber, percent } from 'utils/utils';

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Legend,
  BarChart,
  Bar,
  Cell
} from 'recharts';

import RouterUtils from 'utils/RouterUtils';
import {hashCode} from 'utils/utilsFn';

import moment from 'moment';

// Constants
import { colors } from 'core/constants';

const ScrapChart = (props) => {

  let data = [...(props.productionData || [])]//.concat();
  const isTeam = props.match.params.sector === 'team';
  const dimension = (isTeam)? 'Team' : 'Shift';
  data.shift();
  let legendKeys = [];
  let shiftData = [];
  var maxValue = 0;
  var minValue = 100;


  if(isTeam){
    data = data.map((item, index) => {
      item.teams ? item.teams.forEach((shift, index) => {
        let scrapValue = (parseFloat(shift.scrap) / (shift.countTotal + parseFloat(shift.scrap))) * 100;
        scrapValue = (scrapValue > 0)? scrapValue : 0;
        shift.scrapCalculated = percent(scrapValue);
        if(scrapValue > maxValue){
          maxValue = scrapValue;
        }else if(scrapValue < minValue){
          minValue = scrapValue;
        }
        shift.scrapCalculated = shift.scrapCalculated.replace(',', '.');
        try {
          shift.scrapCalculated = Number(shift.scrapCalculated);
        } catch (error) {
          console.log(error);
        }


        if(item.teams){
          shiftData = (shiftData.length < item.teams.length )? item.teams :shiftData;
        }

        item['line' + shift.team] = shift;
      }) : null;

      //Sort teams in alphabetical order
      item.teams = (item.teams || []).sort((a,b) => (a.team > b.team) ? 1 : ((b.team > a.team) ? -1 : 0));

      return item;
    });
  }else{
    data = data.map((item, index) => {
      item.shifts ? item.shifts.forEach((shift, index) => {

        let scrapValue = (parseFloat(shift.scrap) / (shift.countTotal + parseFloat(shift.scrap))) * 100;
        scrapValue = (scrapValue > 0)? scrapValue : 0;
        shift.scrapCalculated = percent(scrapValue);
        if(scrapValue > maxValue){
          maxValue = scrapValue;
        }else if(scrapValue < minValue){
          minValue = scrapValue;
        }
        shift.scrapCalculated = shift.scrapCalculated.replace(',', '.');
        shift.scrapCalculated = shift.scrapCalculated * 1;

        if(item.shifts){
          shiftData = (shiftData.length < item.shifts.length )? item.shifts :shiftData;
        }
        item['line' + shift.shift] = shift;
      }) : null;

      return item;
    });
  }

  let dataChart = data;

  const isDay = props.match.params.timeRange === 'day';
  const isMonth = props.match.params.timeRange === 'month';
  const isWeekly = props.match.params.aggregationSize === 'by-week';
  const isCustomRange = RouterUtils.constant('isCustomRange');




  const CustomizedXTick = (props) => {
    if (isDay) {
      return (
        <text x={props.x} y={props.y}>
          <tspan x={props.x} dy="15" textAnchor="middle" fill="#666">
            {props.payload.value}h
          </tspan>
        </text>
      );
    }
    if (isMonth && isWeekly  || (isCustomRange && isWeekly)) {
      return (
        <text x={props.x} y={props.y}>
        <tspan x={props.x} dy="15" textAnchor="middle">
          {'CW ' + moment(props.payload.value).format('WW').toUpperCase()}
        </tspan>
        <tspan x={props.x} dy="22" textAnchor="middle" fill="#666">
          {moment(props.payload.value).format('DD/MM')}
        </tspan>
      </text>
      );
    }
    return (
      <text x={props.x} y={props.y}>
        <tspan x={props.x} dy="15" textAnchor="middle">
          {moment(props.payload.value).format('ddd').toUpperCase()}
        </tspan>
        <tspan x={props.x} dy="22" textAnchor="middle" fill="#666">
          {moment(props.payload.value).format('DD/MM')}
        </tspan>
      </text>
    );
  };

  const CustomizedYTick = (props) => {
    return (
      <text x={props.x} y={props.y}>
        <tspan x={(props.x)} dy="4" textAnchor="end">
          {roundNumber(props.payload.value) > 100 ? 100 : roundNumber(props.payload.value)}%
        </tspan>
      </text>
    );
  };

  const CustomizedTooltip = (props) => {
    if (!props.data) return null;
    let value = props.data.filter((item) => item.timestamp === props.label);
    value = value[0] || {};

    let items = null;
    let term = (isTeam) ? "teams" : "shifts";

      items = value[term] ? value[term].map((shift, index) => {
        let i = index + 1;
        value['line' + (isTeam)? shift.team : shift.shift] = shift;
        return (
          <div className="chart-tooltip-item is-danger" key={index}>
            <span>{dimension} {(!isTeam)? shift.shift: shift.team}</span>
            {shift.scrapCalculated === null ? <span>N.A.</span> :
              <span className="hasnoicon">
                {shift.scrapCalculated}
                <span>%</span>
              </span>
            }
          </div>
        );
      }) : null;

    return (
      <div className="chart-tooltip">
        <div className="chart-tooltip-title">
          <h3 className="title is-6">
            {(isDay) ?
              value.timestamp + 'h' :
              ((isMonth && isWeekly) || (isCustomRange && isWeekly)) ?
                'CW ' + moment(value.timestamp).format('WW').toUpperCase() + '  ' + moment(value.timestamp).format('DD/MM').toUpperCase() :
                moment(value.timestamp).format('dddd').toUpperCase() + '  ' + moment(value.timestamp).format('DD/MM').toUpperCase()
            }
            {value.incomplete ? ' (Incomplete)' : ''}
          </h3>
        </div>
        {items}
      </div>
    );
  };

  let shifts = [];
  (shiftData && shiftData.length > 0) ? (shiftData).forEach((shift, index) => {
    if(isTeam){
      shifts.push(['line' + shift.team, shift.team]);
    }else{
      shifts.push(['line' + shift.shift, shift.shift]);
    }
    let i = index + 1;
    legendKeys.push({ label: dimension+' ' + ((!isTeam)? shift.shift: shift.team) });
  }) : null;

  const RenderLegend = (props) => {
    const { legendKeys } = props;
    return (
      <ul style={{ textAlign: 'center' }} className="chart-legend">
        {legendKeys.map((entry, index) => (
          <li key={`item${index}`} className="chart-legend-item">
            <span className="chart-legend-icon" style={{ backgroundColor: colors.secondary[index] }}></span>
            {entry.label}
          </li>
        ))}
      </ul>
    );
  };

  const referenceLines = dataChart[0];
  let intervalData = 'preserveStart';
  if(dataChart.length < 10) intervalData = 0;
  if(dataChart.length > 10 && dataChart.length <= 33) intervalData = 1;
  return (
    <div className="axischart">
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={dataChart} margin={{ top: 20, right: 40, left: 30, bottom: 20 }}>
          <CartesianGrid strokeDasharray="2 3" />
          <XAxis dataKey="timestamp" tick={<CustomizedXTick />} interval={intervalData} />
          <YAxis yAxisId="left" axisLine={false} label={{ position: 'insideLeft' }} tick={<CustomizedYTick />} interval={'preserveStartEnd'} />
          <Tooltip content={<CustomizedTooltip data={dataChart} />} />

          {shifts.map((shift, index) => (
            <Bar
              isAnimationActive={false}
              key={shift[0]}
              yAxisId="left"
              dataKey={shift[0] + '.scrapCalculated'}
              fill={colors.secondary[index]}>
              {dataChart.map((index) => {
                return <Cell key={`cell${index}`} />;
              })}
            </Bar>
          ))}

          <ReferenceLine yAxisId="left"  ifOverflow="extendDomain" isFront={true} y={0} strokeWidth="1" stroke="#ED7671" />
          <ReferenceLine yAxisId="left"  ifOverflow="extendDomain" isFront={true} y={referenceLines ? (referenceLines.scrapAverage/(referenceLines.scrapAverage+referenceLines.singlePeriodAverage))*100 : null} strokeWidth="2" strokeDasharray="2 3" stroke="#888F9D" />
          <Legend content={<RenderLegend />} legendKeys={legendKeys} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

ScrapChart.propTypes = {};

export default ScrapChart;
