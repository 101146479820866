export default [{
  acumTotal: null,
  countTotal: null,
  grossacum: null,
  scrap: null,
  scrapacum: null,
  shift: 1,
  timestamp: null
}, {
  acumTotal: null,
  countTotal: null,
  grossacum: null,
  scrap: null,
  scrapacum: null,
  shift: 2,
  timestamp: null
}, {
  acumTotal: null,
  countTotal: null,
  grossacum: null,
  scrap: null,
  scrapacum: null,
  shift: 3,
  timestamp: null
}];
