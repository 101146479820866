import React from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import * as actions from './actions';

// Components
import AnalyticsHOC from 'containers/Analytics';

// Utils
import ChildRoutes from 'utils/ChildRoutes';
import compareParams from 'utils/compareParams';
import ReactTooltip from 'react-tooltip';

// Misc
const tabList = [{
  name: 'Production',
  key: 'production',
  shift: false,
  team: false
}, {
  name: 'Downtime',
  key: 'downtime',
  shift: false,
  team: false
}, {
  name: 'Scrap',
  key: 'scrap',
  shift: false,
  team: false
},{
  name: 'Manual Events',
  key: 'manual-events',
  shift: false,
  team: false
}];

export class Details extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  /*componentDidMount() {
    this.fetchAllData(this.props);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (compareParams(this.props, prevProps) || prevState.aggregationSize !== this.state.aggregationSize) {
      this.fetchAllData(this.props);
    }
    return null;
  }*/
   
  componentDidUpdate(){
    ReactTooltip.rebuild();
  }

  render() {
    return (
      
      <React.Fragment>
        <ChildRoutes {...this.props} />
        <ReactTooltip className="timeline-tooltip tooltipTable" place="top" type="dark" effect="float" />
      </React.Fragment>
    );
  }

  fetchAllData(props) {
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId
    };
    this.props.fetchProductionData(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchOrders(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchTargets(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchDowntime(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchDowntimeReasons(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchScrap(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchEfficiencyData(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    
  }
}

Details.defaultProps = {
  fetchProductionData: () => {},
  fetchOrders: () => {},
  fetchTargets: () => {},
  fetchDowntime: () => {},
  fetchDowntimeReasons: () => {},
  fetchScrap: () => {},
  fetchEfficiencyData: () => {}
};

Details.propTypes = {
  fetchProductionData: PropTypes.func,
  fetchOrders: PropTypes.func,
  fetchTargets: PropTypes.func,
  fetchDowntime: PropTypes.func,
  fetchDowntimeReasons: PropTypes.func,
  fetchScrap: PropTypes.func
};

export default AnalyticsHOC(connect((store) => ({
  sectorList: store.app.sectorList,
  ordersData: store.Details.ordersData,
  isFetching: store.Details.isFetching,
  productionData: store.Details.productionData
}), actions)(Details), tabList);
