import React from 'react';
import PropTypes from 'prop-types';

import CustomTargets from './containers/CustomTargets';
import Targets from './containers/DefaultTargets';
import ImportCsv from './containers/ImportCSV';
import Tabs from 'lib/Tabs';


// Components
import Grid from 'components/Grid';

// Redux
import { connect } from 'react-redux';
import * as actions from './actions';

// Utils
import buildPath from 'utils/buildPath';
import RouterUtils from 'utils/RouterUtils';

export class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const tabList = [{
      name: 'Targets',
      key: 'targets',
      shift: false
    }, {
      name: 'import CSV',
      key: 'csv-import',
      shift: false
    }];

    const params = RouterUtils.params;
    return (
      <React.Fragment>

        <div className="v-space-default" />
        <div className="v-space-default" />

        <Grid>
          <Grid.Col>
            <Tabs items={tabList} activeSector={params.area} activeKey={params.area} onSelect={this.handleTabSelection.bind(this)} />
          </Grid.Col>
        </Grid>
        {params.area == 'targets'
          ? <React.Fragment>
              <Targets />
              <CustomTargets />
            </React.Fragment>
          :''
        } 
        {params.area == 'csv-import'
          ? <ImportCsv />
          :''
        }

      </React.Fragment>
    );
  }

  handleTabSelection(tab) {
    this.props.history.push(`/app/${this.props.match.params.companyId}/settings/${tab.key}`);
  }

  push() {
    this.props.history.push(`/app/${this.props.match.params.companyId}`);
  }

}

Settings.propTypes = {};

Settings.defaultProps = {};

export default connect((store) => ({
  targets: store.app.targets
}), actions)(Settings);
