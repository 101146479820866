import React from 'react';
import PropTypes from 'prop-types';

import TargetTest from '../components/TargetTest';
import Speed from '../components/Speed';
import Button from 'components/Button';
import Tabs from 'lib/Tabs';
import Components from 'lib/Components';
import ViewSelector from 'lib/ViewSelector';

import { goto, compareParams } from 'utils/utils';

// Components
import Grid from 'components/Grid';
import ViewTitle from 'lib/ViewTitle';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Utils
import buildPath from 'utils/buildPath';
import RouterUtils from 'utils/RouterUtils';


export class Targets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>

        <div className="v-space-default" />
        <div className="v-space-default" />

        <ViewTitle title="Custom Targets" />
        <div className="v-space-default" />
        <div className="v-space-default" />

        <TargetTest {...this.props} />
      </React.Fragment>
    );
  }

  push() {
    this.props.history.push(`/app/${this.props.match.params.companyId}`);
  }

}

Targets.propTypes = {};

Targets.defaultProps = {};

export default connect((store) => ({
  targets: store.app.targets
}), actions)(Targets);
