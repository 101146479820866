import React from 'react';
import PropTypes from 'prop-types';
import CSVReader from 'react-csv-reader';
import ReactFileReader from 'react-file-reader';

import Button from 'components/Button';

// Components
import Grid from 'components/Grid';
import ViewTitle from 'lib/ViewTitle';
import DataTable from 'lib/DataTable';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

export class ImportCsv extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      csvData: null,
      sentFile: false,
    };
  }

  componentWillMount() {
    this.props.fetchDataCSV();
  }
  closeModal() {
    this.setState({
      sentFile: false
    });
  }

  render() {


  const modalCSSClass = `modal is-mobile${
    (this.state.sentFile) ? ' is-active' : ''
  }`;

    return (
      <React.Fragment>

        {(this.state.sentFile)?      
            <div className={modalCSSClass}>
              <div className="modal-background" />
              <div className="modal-content">
                <div className="operator-card">
                  <header className="operator-card-header">
                    <h1 className="title is-label">CSV UPLOAD</h1>
                  </header>
                  <div className="operator-card-body has-text-centered">
                    <div className="v-space-default" />
                    {(!this.props.csvError && this.state.sentFile)?
                    <React.Fragment>
                      <span className="icon is-small is-feedback is-success">
                        <i className="fas fa-check" style={{ fontSize: '2rem' }} />
                      </span>
                      <div className="v-space-default" />
                      <p className="text is-small is-center">
                        Operation succeeded!
                      </p>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <span className="icon is-small is-feedback is-error">
                        <i className="fas fa-exclamation-triangle" style={{ fontSize: '2rem' }} />
                      </span>
                      <div className="v-space-default" />
                      <p className="text is-small is-center">
                        An error has occurred!<br />Please check your file for possible errors.
                      </p>
                    </React.Fragment>
                    }
                    <a
                      className="button is-custom is-white is-fullwidth"
                      onClick={this.closeModal.bind(this)}>DISMISS
                    </a>
                  </div>
                </div>
              </div>
              </div>:
              null
        }

        <div className="v-space-default" />
        <div className="v-space-default" />

        <ViewTitle title="Import a CSV" />

        <div className="v-space-default" />
        <div className="v-space-default" />
        <div className="v-space-default" />

        <Grid desktop>
          <Grid.Col right>
            <ReactFileReader
              fileTypes={['.csv']}
              handleFiles={this.onSendHandleFile.bind(this)}>
              <Button isSuccess isSquare>Upload CSV</Button>
            </ReactFileReader>
          </Grid.Col>
        </Grid>
        <div className="v-space-default" />
        <div className="v-space-default" />

        <DataTable
          mapKeys={{
            'order_id': {
              name: 'Order ID',
              format: 'string'
            },
            'client_name': {
              name: 'Client',
              format: 'string'
            },
            'product_name': {
              name: 'Product',
              format: 'tooltip',
            },
            'production_ordered': {
              name: 'Production Ordered',
              format: 'number'
            },
            'production_programmed': {
              name: 'Production Programmed',
              format: 'number'
            }
          }}
          data={this.props.dataCsv}
          isFetching={this.props.isFetching}
          fetchingKey={'csvUpload'}
        />


      </React.Fragment>
    );
  }

  onSendHandleFile(file) {
    const config = {
      headers: { 'Accept': 'text/csv', 'Content-Type': 'multipart/form-data' }
    };
    let contentCSV = new FormData();
    contentCSV.append('csvContent', file[0]);

    if (file[0] != null) {
      this.props.saveDataCSV(contentCSV, config).then((res) => {
        this.setState({sentFile:true});
        this.props.fetchDataCSV();
      }
        
      ).catch((error)=>{
        this.setState({sentFile:true});
      });
      
    }
  }

  push() {
    this.props.history.push(`/app/`);
  }

}

ImportCsv.propTypes = {};

ImportCsv.defaultProps = {};

export default connect((store) => ({
  targets: store.app.targets,
  dataCsv: store.Settings.dataCsv,
  csvError: store.Settings.csvError,
  isFetching: store.Settings.isFetching
}), actions)(ImportCsv);
