// Components
import NotFoundRoute from 'containers/NotFoundRoute';
import RouteWrapper from 'utils/RouteWrapper';

// Routes
import appRoutes from 'containers/AppWrapper/routes';
import loginRoutes from 'containers/Login/routes';
import customRoutes from 'containers/Custom/routes';

const routes = [{
  component: RouteWrapper,
  routes: [{
    name: 'Login',
    path: '/login',
    component: RouteWrapper,
    routes: loginRoutes
  }, {
    name: 'AppWrapper',
    path: '/app/:companyId?',
    component: RouteWrapper,
    beforeReturn: (props) => {
      const companyId = props.match.params.companyId;
      const appHomeUrl = `/app/${companyId}/analytics/dashboard/factory/month/lines/ALL/total-production/day`;
      if (props.location.pathname === `/app/${companyId}`) {
        props.history.replace(appHomeUrl);
      }else if(props.location.pathname === `/app/` || props.location.pathname === `/app`){
        let url = `/app/1/analytics/dashboard/factory/month/lines/ALL/total-production/day`;
        props.history.replace(url);
      }
    },
    routes: appRoutes
  }, {
    name: 'Custom',
    path: '/custom/',
    component: RouteWrapper,
    routes: customRoutes
  }, {
    path: '**',
    component: NotFoundRoute
  }]
}];

export default routes;
