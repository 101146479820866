import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from './actions';

// Components
import Button from 'components/Button';
import Icon from 'components/Icon';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import Field from 'components/Field';
import ComplexControl from 'components/ComplexControl';
import Control from 'components/Control';
import Box from 'components/Box';
import Form from 'components/Form';
import Picture from 'components/Picture';
import Alert from 'components/Alert';

import SplashScreen, { withSplashScreen } from 'components/SplashScreen';
// Assets
import packiotLogo from 'static/media/packiot-dark.svg';

let timer = setTimeout;

export class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showAlert: false,
      startTransition: false,
      errorAuth: false,
      isAuth: false,
      isRecover: false,
      isRecoverSuccess : false
    };
  }

  componentWillMount() {
    this.props.checkLogin();
  }

  componentWillReceiveProps(nextProps) {
    // If eventKey is new and user tries to put an incorrect email
    if (this.props.eventKey !== nextProps.eventKey && !nextProps.loginIsSuccess) {
      this.loginError.bind(this)();
    }
    // If login success
    if (nextProps.loginIsSuccess && nextProps.loggedUser) {
      this.setState({ startTransition: true, isAuth: true, showAlert: false, isLoading: false, errorAuth: false });

      timer(() => {
        this.props.history.push('/app/' + nextProps.loggedUser.id);
      }, 400);
    }
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  render() {
    return (
      <Layout vhCentered>
        <SplashScreen visible={this.state.startTransition} />

        <Alert
          theme="warning"
          visible={this.state.showAlert}
          fixed="top-right"
          onClose={this.closeAlertHandler.bind(this)}>
          {this.state.errorAuth
            ? <strong>The password is invalid</strong>
            : <strong>Please add a valid email address</strong>
          }
        </Alert>

        <Box maxWidth={300} padding="medium">
        
        {/*this.state.isRecover ?
        <button className="button is-brand-medium-grey is-bare is-medium" onClick={this.hideRecoverPage.bind(this)}>
          <Icon name="arrow-left" />
        </button>: null*/}
        
        
        <a onClick={refreshPage}><Picture src={packiotLogo} alt="PackIOT - Data Matters" maxWidth={220} centered /></a>
          <hr />
          {!this.state.isRecover ?
            <Form onSubmit={this.submitForm.bind(this)}>
              <Field>
                <ComplexControl label="Email" iconsLeft>
                  <Icon name="envelope" left />
                  <input className="input" type="email" name="email" autoComplete="false"
                    onChange={this.inputChangeHandler.bind(this, 'email')}
                  />
                </ComplexControl>
              </Field>
              <Field>
                <ComplexControl label="Password" iconsLeft>
                  <Icon name="lock" small left />
                  <input className="input" type="password" name="password" autoComplete="new-password"
                    onChange={this.inputChangeHandler.bind(this, 'password')}
                  />
                </ComplexControl>
              </Field>
              <hr />
              <Field>
                <Control>
                  <Button
                    theme="primary"
                    loading={this.state.isLoading}
                    disabled={this.state.isLoading}
                    medium submit fullWidth>
                    Sign-in
                  </Button>
                  <p className="has-text-right"><a className="title is-7 is-brand p-t-md " style={{fontWeight: 400}} onClick={this.showRecoverPage.bind(this)}> Change/Forgot password?</a></p>      
                </Control>
              </Field>
            </Form>
          :

            (this.props.loginIsRecoverSuccess == 1 || this.props.loginIsRecoverSuccess == 0) ? 
              (this.props.loginIsRecoverSuccess == 1) ? 
                <p className="has-text-centered">An email has been sent to {this.state.email} with further information.</p>:
                <p className="has-text-centered">An error occurred, try again later.</p>
            :
              <Form>
                <Field>
                  <ComplexControl label="Email" iconsLeft>
                    <Icon name="envelope" left />
                    <input className="input" type="email" name="email" autoComplete="false" defaultValue={this.state.email}
                      onChange={this.inputChangeHandler.bind(this, 'email')}
                    />
                  </ComplexControl>
                </Field>
                <hr />
                <Field>
                  <Control>
                    <Button
                      theme="primary"
                      loading={this.state.isLoading}
                      disabled={this.state.isLoading}
                      medium fullWidth
                      onClick={() => this.resetPassword()}
                      >
                      Recover password
                    </Button>     
                  </Control>
                </Field>
              </Form>
          }
        </Box>
      </Layout>
    );
  }

  showRecoverPage(){
    if(!this.state.isRecover) this.setState({isRecover : true});
  }

  hideRecoverPage(){
    if(this.state.isRecover) this.setState({isRecover : false});
  }

  closeAlertHandler() {
    this.setState({ showAlert: false });
  }

  submitForm() {
    this.setState({ showAlert: false, isLoading: true });
    if (!this.state.email || !this.state.password) this.setState({ showAlert: true, isLoading: false });
    else {
      this.props.login({
        email: this.state.email,
        password: this.state.password
      });
      let self = this;
      if (!this.state.isAuth) {
        setTimeout(() => {
          if (!self.loginIsSuccess) self.setState({ showAlert: true, isLoading: false, errorAuth: true });
        }, 4000);
      }
    }
  }

  resetPassword(){
    this.setState({ showAlert: false, isLoading: true });
    if(!this.state.email){
      this.setState({ showAlert: true, isLoading: false, isRecoverEmail:true });
    }else{
      this.props.resetPassword(this.state.email);
    } 
  }

  inputChangeHandler(name, event) {
    this.setState({ [name]: event.target.value });
  }

  loginError() {
    this.setState({ showAlert: true, isLoading: false });
  }

  
}

function refreshPage(){ 
  window.location.reload(); 
}

Login.propTypes = {};

export default connect((store) => ({
  loggedUser: store.app.loggedUser,
  eventKey: store.login.eventKey,
  loginIsSuccess: store.login.isSuccess,
  loginIsRecoverSuccess :store.login.isRecoverSuccess
}), actions)(withSplashScreen(Login));
