import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../containers/Login/actions';

// Components
import Button from 'components/Button';
import Icon from 'components/Icon';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import Field from 'components/Field';
import ComplexControl from 'components/ComplexControl';
import Control from 'components/Control';
import Box from 'components/Box';
import Form from 'components/Form';
import Picture from 'components/Picture';
import Alert from 'components/Alert';

import SplashScreen, { withSplashScreen } from 'components/SplashScreen';
// Assets
import packiotLogo from 'static/media/packiot-dark.svg';

let timer = setTimeout;

export class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showAlert: false,
      startTransition: false,
      errorAuth: false,
      isAuth: false,
      isRecover: false,
      isRecoverSuccess : false,
      firebaseCode: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    // If eventKey is new and user tries to put an incorrect email
    if (this.props.eventKey !== nextProps.eventKey && !nextProps.loginIsSuccess) {
      this.loginError.bind(this)();
    }
    // If login success
    if (nextProps.loginIsSuccess && nextProps.loggedUser) {
      this.setState({ startTransition: true, isAuth: true, showAlert: false, isLoading: false, errorAuth: false });

      timer(() => {
        this.props.history.push('/app/' + nextProps.loggedUser.id);
      }, 400);
    }
  }

  componentDidMount(){
    this.isCodeValid();
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  componentDidUpdate(){
    if (this.props.isPasswordChangeSuccess == 1){
      timer(() => {
        this.props.history.push('/login/');
      }, 2000);
    }

  }

  render() {
    return (
      <Layout vhCentered>
        <SplashScreen visible={this.state.startTransition} />

        <Alert
          theme="warning"
          visible={this.state.showAlert}
          fixed="top-right"
          onClose={this.closeAlertHandler.bind(this)}>
          {this.state.errorAuth
            ? <strong>The passwords don't match!</strong>
            : <strong>Password should be at least 6 characters</strong>
          }
        </Alert>

        <Box maxWidth={300} padding="medium">
          <a onClick={this.redirectLoginPage.bind(this)}><Picture src={packiotLogo} alt="PackIOT - Data Matters" maxWidth={220} centered /></a>
          <hr />
          {this.props.isCodeValid ?
          
          (this.props.isPasswordChangeSuccess == 1 || this.props.isPasswordChangeSuccess == 0) ? 
          (this.props.isPasswordChangeSuccess == 1) ? 
            <p className="has-text-centered">Your password has been changed successfully.</p>:
            <p className="has-text-centered">An error occurred, try again later.</p>
          
          
          
          :
            <Form onSubmit={this.submitForm.bind(this)}>
              <Field>
                <ComplexControl label="New Password" iconsLeft>
                  <Icon name="lock" left />
                  <input className="input" type="password" name="passwordConfirmation" autoComplete="false"
                    onChange={this.inputChangeHandler.bind(this, 'passwordConfirmation')}
                  />
                </ComplexControl>
              </Field>
              <Field>
                <ComplexControl label="Confirm Password" iconsLeft>
                  <Icon name="lock" small left />
                  <input className="input" type="password" name="password" autoComplete="new-password"
                    onChange={this.inputChangeHandler.bind(this, 'password')}
                  />
                </ComplexControl>
              </Field>
              <hr />
              <Field>
                <Control>
                  <Button
                    theme="primary"
                    loading={this.state.isLoading}
                    disabled={this.state.isLoading}
                    medium submit fullWidth>
                    Change Password
                  </Button>    
                </Control>
              </Field>
            </Form>
          :
            <p className="has-text-centered">This code had already been used or expired.</p>
          }
        </Box>
      </Layout>
    );
  }

  redirectLoginPage(){
    this.props.history.push('/login/');
  }

  showRecoverPage(){
    if(!this.state.isRecover) this.setState({isRecover : true});
  }

  hideRecoverPage(){
    if(this.state.isRecover) this.setState({isRecover : false});
  }

  closeAlertHandler() {
    this.setState({ showAlert: false });
  }

  submitForm() {
    this.setState({ showAlert: false, isLoading: true });
    if (!this.state.passwordConfirmation || (this.state.passwordConfirmation).length < 6  
    || !this.state.password || (this.state.password).length < 6 ) this.setState({ showAlert: true, isLoading: false });
    else {
        if(this.state.passwordConfirmation != this.state.password){ 
            this.setState({ showAlert: true, isLoading: false, errorAuth: true });
        }else{
            this.props.login({
                password: this.state.password
            });
            if (!this.state.isAuth) {
                this.props.changePassword(this.state.firebaseCode, this.state.password);
            }
        }
    }
  }

  isCodeValid(){
    let code = this.props.location.search.substr(this.props.location.search.indexOf('&oobCode=')+9);
    if(code.indexOf("&") != -1) code = code.substr(0, code.indexOf("&")); 
    this.props.isFBCodeValid(code);
    this.setState({ firebaseCode: code});
  }

  inputChangeHandler(name, event) {
    this.setState({ [name]: event.target.value });
  }

  loginError() {
    this.setState({ showAlert: true, isLoading: false });
  }
}

ChangePassword.propTypes = {};

export default connect((store) => ({
  loggedUser: store.app.loggedUser,
  eventKey: store.login.eventKey,
  isPasswordChangeSuccess :store.login.isPasswordChangeSuccess,
  isCodeValid :store.login.isCodeValid
}), actions)(withSplashScreen(ChangePassword));
