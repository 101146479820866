import React from 'react';

import XTick from './components/XTick';
import YTick from './components/YTick';
import ChartLegend from './components/ChartLegend';
import ChartTooltip from './components/ChartTooltip';
import StaticTooltip from './components/StaticTooltip';

import { colors } from 'core/constants';
import { hashCode } from 'utils/utilsFn';

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar
} from 'recharts';


const Lines = (props) => {

  const isTeam = props.isTeam;

  // Create new array to avoid affect the original
  let chartData = props.chartData.concat();

  let multilineChartData = [];
  // Apply a new value in all items without the "incomplete" flag
  if(isTeam){
    chartData = chartData.map((item) => {
      if (!item.incomplete) item.acumTotalComplete = item.acumTotal;
      if (props.isMultiline) {
        item.teams ? item.teams.forEach((shift, index) => {
          if (shift.acumTotal !== null) shift.acumTotal = shift.acumTotal * 1;
          item['line' + shift.team] = shift;
        }) : null;

        if(item.teams){
          multilineChartData = (multilineChartData.length < item.teams.length )? item.teams :multilineChartData;
        }
      }
      return item;
    });
  }else{
    chartData = chartData.map((item) => {
      if (!item.incomplete) item.acumTotalComplete = item.acumTotal;
      if (props.isMultiline) {
        item.shifts ? item.shifts.forEach((shift, index) => {
          if (shift.acumTotal !== null) shift.acumTotal = shift.acumTotal * 1;
          item['line' + shift.shift] = shift;
        }) : null;

        if(item.shifts){
          multilineChartData = (multilineChartData.length < item.shifts.length )? item.shifts :multilineChartData;
        }
      }
      return item;
    });
  }
  let mainLines = (
    <Line
      isAnimationActive={false}
      type="linear"
      yAxisId="left"
      dataKey="efficiency"
      stroke="rgba(57, 159, 239, .6)"
      strokeWidth="4"
      dot={false}
      activeDot={activeDotStyle}
    />
  );
  let mainBars = (
    <Bar dataKey="cnt_production_order" fill="rgba(132, 193, 241, 1)" yAxisId="right" style={{opacity:0.34}} barSize={35} />
  );

  let YAxisOptions = { scales: {
      yAxes: [
          {
              id: 'left',
              ticks: {
                  beginAtZero:true,
                  position:'left'
              }
          },
          {
              id: 'right',
              ticks: {
                  beginAtZero:true,
                  position:'right'
              }
          }
      ]
    }
  }
  if (props.isMultiline) {
    let lines = [];
    let bars = [];
    (multilineChartData && multilineChartData.length > 0) ? (multilineChartData).forEach((shift, index) => {
      if(isTeam){
        lines.push([shift.team, "line"+shift.team]);
      }else{
        lines.push([(shift.shift), "line"+shift.shift]);
      }
    }) : null;
    mainLines = lines.map((line, index) => (

      <Line
        key={line[1]}
        isAnimationActive={false}
        type="linear"
        yAxisId="left"
        dataKey={line[1] + '.efficiency'}
        stroke={colors.secondary[index]}
        strokeWidth="4"
        dot={false}
        activeDot={{ stroke: 'white', fill: colors.secondary[index], strokeWidth: 3, r: 9 }}
      />
    ));
    mainBars = lines.map((line, index) => (

      <Bar dataKey={line[1] + '.cnt_production_order'}  yAxisId="right" style={{opacity:0.34}} fill={colors.secondary[index]} barSize={35} />
    ));
  }

  const chartSettings = { top: 20, right: 40, left: 30, bottom: 20 };
  const activeDotStyle = { stroke: '#FFF', fill: colors.primary.blue, strokeWidth: 3, r: 9 };
  let tooltipCards = ['efficiencyOnTotalProduction','averageProductionOrderTootip','cntProductionOrderTootip'];
  if (props.isMultiline) tooltipCards = ['efficiencyShiftsOnTotalProductionHeader'];

  let intervalData = 'preserveStart';
  if(chartData.length < 10) intervalData = 0;
  if(chartData.length > 10 && chartData.length <= 33) intervalData = 1;

  return (
    <div className="axis-chart has-panel-overlaid">
      <ResponsiveContainer width="100%" height={500}>
        <ComposedChart  data={chartData} margin={chartSettings} options={YAxisOptions} >
          <CartesianGrid strokeDasharray="2 3" />

          <XAxis
            dataKey="timestamp"
            tick={<XTick hideFirstTick />}
            interval={intervalData}
          />

          <YAxis
            orientation='left'
            yAxisId="left"
            tick={<YTick  isPercentage={true}/>}
            interval={'preserveStartEnd'}
          >
            <Label angle={-90} value='OEE Progress' position='left' style={{textAnchor: 'middle'}} />
          </YAxis>

          <YAxis
            orientation='right'
            yAxisId="right"
            allowDecimals={false}
            interval={'preserveStartEnd'}
            dataKey={(props.isMultiline)?null:"cnt_production_order"}
          >
            <Label angle={-90} value='Change Over Count' position='right' style={{textAnchor: 'middle'}} />
          </YAxis>

          <Tooltip
            content={(
              <ChartTooltip
                chartData={chartData}
                cards={tooltipCards}
              />
            )}
          />

          {mainBars}
          {mainLines}

          <Legend
            content={<ChartLegend />}
            legendKeys={props.legendKeys}
          />

        </ComposedChart >
      </ResponsiveContainer>
    </div>
  );
};

Lines.defaultProps = {
  chartData: [],
  legendKeys: []
};

export default Lines;
