import React from 'react';
import PropTypes from 'prop-types';
import Treemap from 'react-vis/dist/treemap';

// Components
import Button from 'components/Button';
import TreeMapLabel from './TreeMapLabel';

export default class TreeMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: 0,
      display: 'none',
      tooltipData: {},
      treeData: {
        children: []
      },
      depth: {
        machines: {
          name: 'Machines',
          isEnabled: true
        }
      }
    };
    this.colors = ['#D678F7', '#7CEC87', '#F3AC76', '#63ADF5', '#BCBFC5'];
  }

  componentWillReceiveProps(nextProps, nextState) {
    let data = (nextProps.data || []);
    let treeData = {
      title: 'Lines',
      color: 'rgba(237, 118, 113, 0)',
      children: []
    };

    data.forEach((item, index) => {

      let children = [];
      let lineValue = null;

      item.machines.forEach((item) => {
        lineValue += item.scrap;
        if (item.scrap === 0) return;
        children.push({
          name: item.machineName,
          size: item.scrap,
          color: this.colors[index]
        });
      });

      treeData.children.push({
        title: 'Machines',
        name: item.lineName,
        enabledSize: item.scrap,
        children: children,
        color: this.colors[index]
      });
    });
    this.setState({ treeData: treeData });
  }

  render() {
    const { top, left, display } = this.state;
    const screenWidth = window.innerWidth - 10;
    const legendItems = this.state.treeData.children.map((item, index) => (
      <li className="recharts-legend-item legend-item-1" style={{ display: 'inline-block', margin: 15 }} key={item.name}>
        <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32"
          version="1.1">
          <path fill={this.colors[index]}
            className="recharts-symbols"
            transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
          />
        </svg>
        <span className="recharts-legend-item-text"
          style={{ display: 'inline-block', verticalAlign: 'text-bottom', paddingLeft: 5 }}>
          {item.name}
        </span>
      </li>
    ));

    return (
      <div className="axis-chart">
        <div className="swicth-button-container">
          <Button className="switch-button"
            isToggled={!this.state.depth.machines.isEnabled}
            onClick={this.toggle.bind(this, 'machines')}>
            <span className="switch-button-token" />
            <span className="switch-label-primary">Lines</span>
            <span className="switch-label-secondary">Machines</span>
          </Button>
        </div>
        <div
          onTouchMove={this.handleTouchMove.bind(this)}
          onMouseMove={this.handleMouseMove.bind(this)}
          onMouseLeave={this.handleMouseLeave.bind(this)}
          ref={(el) => this.treemapContainer = el}>
          <Treemap
            title={'Treemap'}
            mode={'squarify'}
            width={screenWidth}
            height={450}
            padding={0}
            data={this.state.treeData}
            colorType={'literal'}
            hideRootNode={true}
            onLeafMouseOver={this.onLeafMouseOver.bind(this)}
            onLeafMouseOut={this.onLeafMouseOut.bind(this)}
            getColor={(d) => d.color}
            getLabel={(label) => <TreeMapLabel value={label} />}
          />
          {this.state.tooltipData.name ?
            <div
              className="chart-tooltip-treemap"
              style={{ display: display, position: 'fixed', top: top, left: left }}
              ref={(el) => this.el = el}>
              <div className="chart-tooltip" style={{ textAlign: 'center' }}>
                <div className="chart-tooltip-title">
                  <h3 className="title is-6">
                    {this.state.tooltipData.name}
                  </h3>
                </div>
                <div className="chart-tooltip-item is-danger">
                  <span>Scrap</span>
                  <span className="has-no-icon">
                    {this.state.tooltipData.percent}%
                  </span>
                </div>
              </div>
            </div>
            : null}
        </div>
        <div className="recharts-legend-wrapper" style={{ marginTop: 20 }}>
          <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendItems}
          </ul>
        </div>
      </div>
    );
  }
  onLeafMouseOver(value) {
    value.data.percent = Math.round((value.value * 100) / value.parent.value);
    this.setState({ tooltipData: value.data });
  }

  onLeafMouseOut() { }

  handleTouchMove() { }

  handleMouseMove(event) {
    if (!this.el) return;
    const screenWidth = window.innerWidth / 2;
    const { width, height } = this.el.getBoundingClientRect();
    const treemapContainer = this.treemapContainer.getBoundingClientRect();
    const mouseX = event.clientX > screenWidth ? (event.clientX - width) - 5 : event.clientX + 10;
    const mouseY = event.clientY > treemapContainer.height ? (event.clientY - height) - 5 : event.clientY + 10;
    this.setState({
      left: mouseX,
      top: mouseY,
      display: 'initial'
    });
  }

  handleMouseLeave() {
    this.setState({ display: 'none' });
  }

  toggle(depthKey) {
    let filteredTreeData = Object.assign({}, this.state.treeData);
    const isEnabled = !this.state.depth[depthKey].isEnabled;
    filteredTreeData.children.map((item) => {
      if (item.title === this.state.depth[depthKey].name) {
        item.size = !isEnabled ? item.enabledSize : null;
        item.style = { display: !isEnabled ? 'initial' : 'none' };
        item.children.map((child) => {
          child.style = { display: isEnabled ? 'initial' : 'none' };
          return child;
        });
      }
      return item;
    });

    this.setState({
      treeData: filteredTreeData,
      depth: {
        [depthKey]: {
          name: this.state.depth[depthKey].name,
          isEnabled: isEnabled
        }
      }
    });
  }
}

TreeMap.propTypes = {};
