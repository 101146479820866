import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Components
import Events from 'lib/dataComponents/Events';
import ProductionFlow from './components/FlowSection';
import LoadingContainer from 'components/LoadingContainer';
import Grid from 'components/Grid';
import NoData from 'lib/dataComponents/components/NoData';

// Utils
import compareParams from 'utils/compareParams';

export class LinesComponents extends React.PureComponent {
  constructor(props){
    super(props);
  }

  componentWillMount(){
    let nextParams = this.props.match.params;
    let component = this.props.sectorList.find(e => e.name == nextParams.componentId);
    let mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId,
      lineId: (component) ? component.id_equipment:null,
      siteId: (this.props.sitesList)? this.props.sitesList.id_site : null,
      areaId: (this.props.sitesList)? this.props.sitesList.areas[0].id_area : null,
      sector: nextParams.sector
    };
    this.props.fetchNewProductionFlow(mainParams, {
      timeRange: nextParams.timeRange
    });
    this.props.fetchProductionFlow(mainParams, {
      timeRange: nextParams.timeRange
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (compareParams(this.props, prevProps) || prevProps.sectorList !== this.props.sectorList || prevProps.sitesList !== this.props.sitesList) {      
      const nextParams = this.props.match.params;
      const component = this.props.sectorList.find(e => e.name == nextParams.componentId);
      const mainParams = {
        component: nextParams.component,
        componentId: nextParams.componentId,
        lineId: (component) ? component.id_equipment:null,
        sector: nextParams.sector,
        siteId: (this.props.sitesList)? this.props.sitesList[0].id_site : null,
        areaId: (this.props.sitesList)? this.props.sitesList[0].areas[0].id_area : null,
      };
      this.props.fetchNewProductionFlow(mainParams, {
        timeRange: nextParams.timeRange
      });
      this.props.fetchProductionFlow(mainParams, {
        timeRange: nextParams.timeRange
      });
      
      
    }
    return null;
  }

  render() {
    const isShift = this.props.match.params.sector === 'shift';
    const isAllLines = this.props.match.params.componentId === 'ALL';
    return (
      <Grid>
        <Grid.Col>
          <LoadingContainer isActive={this.props.isFetching.productionFlow || this.props.isFetching.stoppedTimeProductionFlow}>
            <div style={{ height: 40 }} />
            {/* {isDay && isAllLines ? <Events {...this.props} /> : null} */}
            {!isAllLines && !isShift
              ? <ProductionFlow {...this.props} />
              : <NoData />
            }
          </LoadingContainer>
        </Grid.Col>
      </Grid>
    );
  }
}

LinesComponents.defaultProps = {
  fetchFlowSection: () => { },
  constant: () => { }
};

LinesComponents.propTypes = {
  fetchFlowSection: PropTypes.func,
  constant: PropTypes.func
};

export default connect((store) => ({
  productionFlow: store.Dashboard.productionFlow,
  stoppedTimeProductionFlow: store.Dashboard.stoppedTimeProductionFlow,
  currentProduction: store.Dashboard.currentProduction,
  isFetching: store.Dashboard.isFetching,
}), actions)(LinesComponents);