import React from 'react';
import PropTypes from 'prop-types';

const Speed = (props) => {
  return (
    <div>
      Speed
    </div>
  );
};

Speed.propTypes = {};

export default Speed;
