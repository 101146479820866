import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
// es6
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

import Grid from 'components/Grid';
import DataTable from 'lib/DataTable';
import Field from 'components/Field';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import Form from 'components/Form';
import Button from 'components/Button';
import Icon from 'components/Icon';

import moment from 'moment';



export class TargetTest extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.manipulateData(),
      message: '',
      selected: [],
      currentItem: {
        lineName: null,
        period: null,
        value: null
      },
      lineSelected: '--',
      goalSelected: 'DAY'
    };
   // this.renderEditable = this.renderEditable.bind(this);
    this.addNewRow = this.addNewRow.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSuccess !== this.props.isSuccess && nextProps.isSuccess !== null) {
      this.setState({ message: nextProps.isSuccess ? 'Values updated.' : 'Sorry, an error ocurred. Please try again.' });
      this.props.fetchTargets();
      setTimeout(() => {
        this.setState({ message: '' });
      }, 1300);
    }
  }


  render() {
    const targets = [{
      id: 'DAY',
      name: 'Daily'
    }, {
      id: 'WEEK',
      name: 'Weekly'
    }, {
      id: 'MONTH',
      name: 'Monthly'
    }];

    let lines = [];
    if (this.props.sectorList && this.props.sectorList.length > 0) {
      lines = this.props.sectorList;
      if (lines[0].id === 'ALL') lines.shift();
    }
    
    const targetsArray = (targets || []);
    const linesArray = (lines || []);

    const dataDropdownGoalPeriod = targetsArray.map((item) => (
      <button
        type="button"
        className={'dropdown-item'}
        onClick={()=>
          this.getGoalData('goalSelected',item.id)
        }
        key={item.id}>
        {item.name}
      </button>
    ));


    const dataDropdownLines = linesArray.map((item) => (
      <button
        type="button"
        className={'dropdown-item'}
        onClick={() => this.getGoalData('lineSelected',item.id_equipment)
        }
        key={item.id}>
        {item.name}
      </button>
    ));

    const deleteFormatter = (cell, row, rowIndex, formatExtraData) => {
      return ( 
        <a className='deleteGoal' onClick={() => this.deleteGoalOnDb(row)}>
          <Icon name='trash-alt' />
        </a>
      );
    }

    const selectRow = {
      mode: 'checkbox',
      selected: this.state.selected,
      hideSelectColumn: true,
      classes: 'deletedRow'
    };

    const defaultSorted = [{
      dataField: 'ts_target',
      order: 'desc' // desc or asc
    }];

    const columns = [{
      dataField: 'ts_target',
      text: 'Date',
      validator: (newValue, row, column) => {  
        if(this.state.data.find(r => r.ts_target == newValue)){  
          return {
            valid: false,
            message: 'Cannot add a duplicate entry for this period.'
          };
        }
      },
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <DatePicker {...editorProps} value={value} type={this.getDataPickerType()}/>
      ),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => { 
        if (order === 'asc') {
          return moment(b, 'DD/MM/YYYY').unix() - moment(a, 'DD/MM/YYYY').unix();
        }
        return moment(a, 'DD/MM/YYYY').unix() - moment(b, 'DD/MM/YYYY').unix(); // desc
      },
    }, {
      dataField: 'target',
      text: 'Goal',
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'Goal should be numeric'
          };
        }
        return true;
      },
      sort: true
    }, {
      dataField: 'edit',
      text: '',
      editable: false,
      align: "right",
      headerStyle: { width: 50 },
      formatter: deleteFormatter 
    }];
    
    return (
      <React.Fragment>
          <Grid>
            <Grid.Col>
              <label className="dropdown-label">Lines</label>
              <Dropdown isField trigger={<React.Fragment><span>{(this.state.lineSelected != "--" && linesArray.find(line => line.id_equipment == this.state.lineSelected ))?linesArray.find(line => line.id_equipment == this.state.lineSelected ).name: this.state.lineSelected}</span> <Icon name="angle-down" /></React.Fragment>}>
                {dataDropdownLines}
              </Dropdown>
            </Grid.Col>
            <Grid.Col>
              <label className="dropdown-label">Goal Period</label>
              <Dropdown isField trigger={<React.Fragment><span>{targetsArray.find(target => target.id === this.state.goalSelected).name}</span> <Icon name="angle-down" /></React.Fragment>}>
                {dataDropdownGoalPeriod}
              </Dropdown>
            </Grid.Col>
          </Grid>
          {(this.state.lineSelected == "--")? 
            <h3 className="title is-6 is-danger is-uppercase">Please select a line</h3>
          : (!this.props.isFetching.customTarget)?  
          <div>
            <BootstrapTable
              classes="goalTable"
              keyField='id_target'
              data={ this.state.data }
              defaultSorted={ defaultSorted }
              columns={ columns }
              selectRow={ selectRow }
              cellEdit={ cellEditFactory({
                mode: 'click',
                blurToSave: true,
                afterSaveCell: (oldValue, newValue, row, column) => { this.updateGoalOnDb(oldValue,newValue,row) }
              }) }
              striped
            />
            <Button isSuccess isSquare onClick={this.addNewRow.bind(this)}>Add row</Button>
          </div>
          :
            <div className="datatable-loading">
              <div className="packiot-loading" />
              <span className="datatable-loading-info">{'Table is fetching data. If it\'s taking too long please select another period.'}</span>
            </div>
          }
        
        <Grid>
          <Grid.Col>
            <span className="title is-6 is-dark">{this.state.message}</span>
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );

  }

  handleChange(type, field, callback) {
    const fieldValue = typeof field === 'string' ? field : field.target.value;
    let items = this.state.currentItem;
    let value = fieldValue || null;
    items[type] = value;
    if (type === 'value' && !!parseInt(fieldValue)) {
      items[type] = parseInt(value);
    } else if (type === 'value' && !!!parseInt(fieldValue)) {
      this.setState({ message: 'Please, inform a integer value to Goal field.' });
      return;
    }
    this.setState({
      currentItem: items,
      message: ''
    });
    if (callback) callback();
  }


  addNewRow(){ 
    if(this.state.data.find(line => (line.ts_target == " " ||line.target+"" == " " || line.id_target == null)) == undefined){
      let data = {
        ts_target: " ",
        target: " ",
        id_target:  null
      }; 
      this.setState({ data: this.state.data.concat(data)});
    }
  }

  updateGoalOnDb(oldValue,newValue,row){
    if(oldValue != newValue && row.ts_target != " " && row.target != " " ){
      
      let date = moment(row.ts_target, 'DD/MM/YYYY').format('YYYY-MM-DD');
     
      this.props.updateGoalData(this.state.goalSelected,this.state.lineSelected,{ts_target:date ,target_value: parseInt(row.target),target_id: parseInt(row.id_target)}).then(() => {
        this.props.fetchGoalPeriod(this.state.goalSelected,this.state.lineSelected).then(() => {
          this.setState({data : this.manipulateData()});
        });
      });
      
    }
  }

  deleteGoalOnDb(row){
    this.setState({selected : [row.id_target] });
    this.props.deleteGoalData(this.state.goalSelected,{target_id: parseInt(row.id_target)}).then(() => {
      this.props.fetchGoalPeriod(this.state.goalSelected,this.state.lineSelected).then(() => {
        this.setState({data : this.manipulateData(), selected : []});
      });
    });
  }

  getDataPickerType(){
    var type = "date"
    switch(this.state.goalSelected){

      case 'DAY':
        type = "date";
        break;
      case 'MONTH':
        type = "month";
        break;
      case 'WEEK':
        type = "week";
        break;
    }
    return type;
  }

  checkCurrent() {
    const isValid = (
      !this.state.currentItem.lineName ||
      !this.state.currentItem.period ||
      !this.state.currentItem.value
    );
    return !isValid;
  }

  getGoalData(t,item){
    this.setState({ 
      [t]: item
    }, () =>{
      this.props.fetchGoalPeriod(this.state.goalSelected,this.state.lineSelected).then(() => {
        this.setState({data : this.manipulateData()});
      });
    });    
  }

  generateID(){
    let id = 0
    while(this.state.data.find(line => line.id == id) !== undefined){
      id = Math.floor((Math.random() * 10000) + 1);
    }
    return id;
  }

  manipulateData(){
    let data = [];
    (this.props.goalData) ? (this.props.goalData).forEach((goal, index) => {
      goal.ts_target = moment(goal.ts_target, 'YYYY-MM-DD').format('DD/MM/YYYY');
      goal.id = index;
      data.push(goal);
    }) : null;
    return data;
  }

}

class DatePicker extends React.Component {

  handleDateType(value,type){
    if(type == "date"){
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }else if(type == "week"){
      return moment(value, 'YYYY-WWW').format('DD/MM/YYYY')
    }else if( type == "month"){
      return moment(value, 'YYYY-MM').format('DD/MM/YYYY')
    }
  }

  render() {
    const { type, value, onUpdate, ...rest } = this.props;

    let defaultValue = "";
    switch(type){
      case "date":
        defaultValue= moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        break;
      case "week":
        defaultValue=  moment(value, 'DD/MM/YYYY').format('YYYY-WW');
        defaultValue = defaultValue.substr(0 , defaultValue.indexOf('-')+1)+"W"+defaultValue.substr(defaultValue.indexOf('-')+1);
        break;
      case "month":
        defaultValue= moment(value, 'DD/MM/YYYY').format('YYYY-MM');
        break;
    }
    return (
      <input
          key="submit"
          className="input"
          type={type}
          defaultValue={defaultValue}
          onChange={e => onUpdate(this.handleDateType(e.target.value, type))}
      />
      );
  }
}

TargetTest.propTypes = {};

export default connect((store) => ({
  targets: store.app.targets,
  sectorList: store.app.sectorList,
  isSuccess: store.Settings.saveIsSuccess,
  goalData: store.Settings.goalData,
  isFetching: store.Settings.isFetching,
}), actions)(TargetTest);
