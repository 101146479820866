import React from 'react';

// Components
import Login from 'containers/Login';
import ChangePassword from 'components/ChangePassword';
import UnauthorizedLogin from 'components/UnauthorizedLogin';

const routes = [{
  name: 'Login',
  path: '/login',
  exact: true,
  component: Login
},{
  name: 'Change password',
  path: '/login/changePassword',
  exact: true,
  component: ChangePassword,
},{
  name: 'Access not authorized',
  path: '/login/unauthorized',
  exact: true,
  component: UnauthorizedLogin
}];

export default routes;