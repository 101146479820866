import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Components
import Grid from 'components/Grid';
import DataTable from 'lib/DataTable';
import Filter from 'lib/Filter';
import TimelineBar from 'lib/TimelineBar';
import Pagination from 'react-js-pagination';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { CSVLink } from "utils/react-csv";
import moment from 'moment';

import compareParams from 'utils/compareParams';



export class Production extends React.Component {
  constructor(props) {
    super(props);
    //routerProps(this);
    this.state = {
      filterCss: [],
      isFilterOpen: false,
      suggestions: []
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount(){
    this.fetchAllData(this.props);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (compareParams(this.props, prevProps) || prevState.aggregationSize !== this.state.aggregationSize) {
      this.fetchAllData(this.props);
    }
    return null;
  }

  fetchAllData(props) {
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId
    };
    this.props.fetchClients();
    this.props.fetchProductionData(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchOrders(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
  }
  
  handleClick() {
    if (!this.state.isFilterOpen) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      isFilterOpen: !prevState.isFilterOpen,
    }));
  }
  
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node != null && (this.node.contains(e.target) ||e && e.target.className == "ReactTags__remove")) {
      return;
    }
    this.handleClick();
  }


  render() {
    // Save isFetching prop
    const isFetching = this.props.isFetching;
    const mapKeys = {
      'Status': {
        name: 'Status',
        format: 'status',
        orderBy: 'Status'
      },
      'Client': {
        name: 'Client',
        format: 'string',
        orderBy: 'Client',
        filter: true,
        filterType: 'tag'
      },
      'PO': {
        name: 'PO',
        format: 'string',
        orderBy: 'PO',
        filter: true,
        filterType: 'input'
      },
      'Product': {
        name: 'Product',
        format: 'tooltip',
        orderBy: 'Product',
        filter: true,
        filterType: 'input'
      },
      'Production': {
        name: 'Production',
        format: 'number',
        orderBy: 'Production'
      },
      'production_collected': {
        name: 'Production Collected',
        format: 'number',
        orderBy: 'Production Collected'
      },
      'Line': {
        name: 'Line',
        format: 'string',
        orderBy: 'Line'
      },
      'Order size': {
        name: 'Order size',
        format: 'number',
        orderBy: 'Order size',
        filter: true,
        filterType: 'combobox',
        filterValues: [{text: '--', min: null, max: null},{text: '< 10K', min: 0, max: 10000},{text: '> 10K - < 25K', min: 10000, max: 25000},{text: '> 25K - < 50K', min: 25000, max: 50000}, {text: '> 50K - < 100K', min: 50000, max: 100000}, {text: '> 100K', min: 100000, max: 999999999}]
      },
      'Scrap': {
        name: 'Scrap',
        csvName: 'Scrap (%)',
        format: 'scrap',
        orderBy: 'Scrap'
      },
      'Start': {
        name: 'Start',
        orderBy: 'Start.value'
      },
      'End': {
        name: 'End',
        orderBy: 'End.value'
      },
      'Total time': {
        name: 'Total time',
        csvName: 'Total time (s)',
        orderBy: 'Total time.value',
        filter: true,
        filterType: 'combobox',
        filterValues: [{text: '--', min: null, max: null},{text: '< 4h', min: 0, max: 14400},{text: '> 4h - < 12h', min: 14400, max: 43200},{text: '> 12h - < 18h', min: 43200, max: 64800}, {text: '> 18h - < 24h', min: 64800, max: 86400}, {text: '> 24h - < 36h', min: 86400, max: 129600}, {text: '> 36h', min: 129600, max: 9999999}]
      }
    };

    let csvHeader = (Object.keys(mapKeys) || []).map((key) => {
      if(mapKeys[key].csvName){
        return ({label: mapKeys[key].csvName, key: key });
      }
      return ({label: mapKeys[key].name, key: key });
    });

    let csvData = ((this.props.ordersData)? this.props.ordersData.orders : []).map((item) =>{
      return({
        'Status': item.Status,
        'Client': item.Client,
        'PO': item.PO,
        'Product': item.Product,
        'Production': item.Production,
        'production_collected': item.production_collected,
        'Line': item.Line,
        'Order size': item['Order size'],
        'Scrap': item.Scrap,
        'Start':  moment(item.Start.value).format('DD-MM-YYYY HH:mm'),
        'End': moment(item.End.value).format('DD-MM-YYYY HH:mm'),
        'Total time': item['Total time'].value,  
      })
    });

    let suggestion = (this.props.clientsList || []).map(item =>{
      return {id: item.client_id,text: item.client_name}
    });
    if(JSON.stringify(suggestion)!=JSON.stringify(this.state.suggestions)){
      this.setState({suggestions: suggestion})
    }
    return (
      <React.Fragment>
        <Grid>
          <Grid.Col className="is-hidden-touch relative">
            <div className="v-space-small" />
            <h3 className="title is-6 is-uppercase">
              Production Orders{/*by product family*/}
            </h3>
            <Button specSelector="settings" className="filter" theme="brand-medium-grey" onClick={() => {this.handleClick()}} bare medium>
              <Icon name="sliders-h" />
            </Button>
            {this.props.ordersData?
            <CSVLink data={csvData} separator={";"} className="button is-brand-medium-grey is-bare is-medium filter" filename={"production-orders-"+moment().format("YYYY-MM-DD")+".csv"} headers={csvHeader}>
              <Icon name="file-csv" fal={true} />
            </CSVLink>
            :null}
            <div ref={node => { this.node = node; }}>
              <Filter mapKeys={mapKeys} isFilterOpen={this.state.isFilterOpen} save={this.fetchFilter.bind(this)} suggestions={this.state.suggestions} innerRef={el => { this.el = el }}/>
            </div>
            <hr className="hr is-small" />
            <TimelineBar data={this.props.productionData} isFilterOpen={this.state.isFilterOpen}/>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col className="datatable-wrapper">
            <DataTable mapKeys={mapKeys}
              data={this.props.ordersData} error={this.props.error} isFetching={this.props.isFetching} filter={true} isFilterOpen={this.state.isFilterOpen} totalProduction fetchingKey={'fectchOrders'}
            />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  handleAggregationSize({ target }) {
    this.props.updateAggregationSize(target.value);
  }

  fetchFilter(fields){
    setTimeout(function (){
      this.setState({
        isFilterOpen: false,
      });
    }.bind(this), 500);
    var props = this.props;
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId
    };
    this.props.fetchOrders(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    },fields);
  }
}

Production.defaultProps = {
  isFetching: {},
  fetchOrders: () => {},
  updateAggregationSize: () => { }
};

Production.propTypes = {
  updateAggregationSize: PropTypes.func,
  fetchOrders: PropTypes.func
};

export default connect((store) => ({
  ordersData: store.Details.ordersData,
  isFetching: store.Details.isFetching,
  productionData: store.Details.productionData,
  error: store.Details.error,
  errorRequest: store.Details.errorRequest,
  clientsList: store.Details.clientsList,
}), actions)(Production);
