import Downtime from './containers/Downtime';
import Production from './containers/Production';
import Scrap from './containers/Scrap';
import ManualEvents from './containers/ManualEvents';

const routes = [{
  name: 'Production',
  path: '/app/:companyId/analytics/details/:sector/:timeRange/:component/:componentId/production',
  component: Production
}, {
  name: 'Downtime',
  path: '/app/:companyId/analytics/details/:sector/:timeRange/:component/:componentId/downtime',
  component: Downtime
}, {
  name: 'Scrap',
  path: '/app/:companyId/analytics/details/:sector/:timeRange/:component/:componentId/scrap',
  component: Scrap
}, {
  name: 'Manual Events',
  path: '/app/:companyId/analytics/details/:sector/:timeRange/:component/:componentId/manual-events',
  component: ManualEvents
}];

export default routes;
