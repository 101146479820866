import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Components
import Grid from 'components/Grid';
import TreeMap from '../components/TreeMap';
import LoadingContainer from 'components/LoadingContainer';

import compareParams from 'utils/compareParams';

export class Scrap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.fetchAllData(this.props);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (compareParams(this.props, prevProps) || prevState.aggregationSize !== this.state.aggregationSize) {
      this.fetchAllData(this.props);
    }
    return null;
  }

  fetchAllData(props) {
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId
    };

    this.props.fetchScrap(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    
  }

  render() {
    const isFetching = this.props.isFetching;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <LoadingContainer isActive={isFetching.scrapDetails}>
              <TreeMap data={this.props.scrapDetails || []} />
            </LoadingContainer>
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  handleAggregationSize({ target }) {
    this.props.updateAggregationSize(target.value);
  }
}

Scrap.defaultProps = {
  isFetching: {},
  scrapDetails: [],
  updateAggregationSize: () => { }
};

Scrap.propTypes = {
  updateAggregationSize: PropTypes.func,
  scrapDetails: PropTypes.array,
  isFetching: PropTypes.object
};

export default connect((store) => ({
  scrapDetails: store.Details.scrapDetails,
  isFetching: store.Details.isFetching
}), actions)(Scrap);