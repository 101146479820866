import React from 'react';

import { rawNumber, fixNumber, isInvalidNumber, prettyNumber, hashCode } from 'utils/utilsFn';
import { roundNumber } from 'utils/utils';
import dataHelpers from 'lib/dataHelpers';

import { colors } from 'core/constants';

const ChartTooltipCards = {};

ChartTooltipCards.shiftsOnTotalProduction = (props) => {
  // console.log(props);
  // const value = dataHelpers.toGoalOnTotalProduction(props);
  // if (!value) return null;
  // const CSSClass = `chart-tooltip-item${
  //   rawNumber(value) < 0 ? ' is-danger' : (rawNumber(value) == 0 ? ' is-brand' : ' is-success')
  // }`;
  let items = null;
  let term = (props.teams) ? 'teams' : 'shifts' ;

  items = props[term]? props[term].map((shift, index) => {
    // value['shift' + index] = shift;
    // console.log(prettyNumber(shift.acumTotal, 2, 'pt-BR'));
    const { value, token } = prettyNumber(shift.acumTotal, 2, 'pt-BR');
    ///const { formatted } = prettyNumber(shift.target, 2, 'pt-BR');
    const toGoal = (isInvalidNumber(Math.floor(((shift.acumTotal - shift.targetacum) / shift.targetacum) * 100))) ? 0 : Math.floor(((shift.acumTotal - shift.targetacum) / shift.targetacum) * 100);
    const toGoalValue = ` (${
      toGoal < 0 ? toGoal + '%' : (toGoal == 0 ? toGoal : '+' + toGoal + '%')
      } of goal)`;
    let toGoalCSSClassInfoBox = `chart-tooltip-item${
      toGoal < 0 ? ' is-danger' : (toGoal == 0 ? ' is-brand' : ' is-success')
      }`;
    
    const dimension = (props.teams)? 'Team' : 'Shift';
    return (
      <div className={toGoalCSSClassInfoBox} key={index}>
        <span>{dimension} {(shift.shift && !props.teams)? shift.shift: shift.team} <br /><span className="subtitle-shift">{toGoalValue}</span></span>
        {shift.acumTotal === null ? <span>N.A.</span> :
          <span>
            {value}
            <span>{token}</span>
          </span>
        }
      </div>
    );
  }) : null;

  return items;
};

ChartTooltipCards.efficiencyShiftsOnTotalProduction = (props) => {
  
  const dimension = (props.teams)? 'Team' : 'Shift';
  let items = null;

  let term = (props.teams) ? 'teams' : 'shifts' ;

  items = props[term] ? props[term].map((shift, index) => {
    const { value } = prettyNumber(shift.efficiency, 2, 'pt-BR');
    return (
      <div className="chart-tooltip-item is-brand" key={index}>
        <span>{dimension} {(shift.shift && !props.teams)? shift.shift: shift.team} <br /></span>
        {shift.acumTotal === null ? <span >N.A.</span> :
          <span>
            {fixNumber(parseFloat(value), 2, 'pt-BR')}
            <span>%</span>
          </span>
        }
      </div>
    );
  }) : null;

  return items;
};

ChartTooltipCards.efficiencyShiftsOnTotalProductionHeader = (props) => {
  let header = [{title: 'OEE', key: 'efficiency'},{title: 'Avg. production', key: 'avg_production_order'},{title: 'Change over count', key: 'cnt_production_order'}]

  header = header.map((e, index)  => {

  const dimension = (props.teams)? 'Team' : 'Shift';
  let items = null;

  let term = (props.teams) ? 'teams' : 'shifts' ;

  items = props[term] ? props[term].map((shift, index) => {
      const val = prettyNumber(shift[e.key], 2, 'pt-BR');
      let name = (shift.shift && !props.teams)? shift.shift: shift.team;
      let token = '%';
      let value = fixNumber(parseFloat(val.value), 2, 'pt-BR')
      if(e.key != 'efficiency'){
        value = val.value;
        token = val.token;
       
      }
      return (
        <div className={"chart-tooltip-item is-brand is-"+term} key={index}>
          {shift.acumTotal === null ? <span >N.A.</span> :
            <span style={{color: colors.secondary[index]}}>
              {value}<span>{token}</span>
            </span>
          }
        </div>
      );
    }) : null;

    return(
      <div className="chart-tooltip-item is-brand" key={index}>
        <span>{e.title} </span>
        {items}
      </div>
    );
  });

  return header;
};

ChartTooltipCards.toGoalOnTotalProduction = (props) => {
  const value = dataHelpers.toGoalOnTotalProduction(props);
  if (!value) return null;
  const CSSClass = `chart-tooltip-item${
    rawNumber(value) < 0 ? ' is-danger' : (rawNumber(value) == 0 ? ' is-brand' : ' is-success')
    }`;
  return (
    <div className={CSSClass}>
      <span>To Goal</span>
      <span>
        {value}<span>%</span>
      </span>
    </div>
  );
};

ChartTooltipCards.toGoalOnSinglePeriod = (props) => {
  const value = dataHelpers.toGoalOnSinglePeriod(props);
  if (!value) return null;
  const CSSClass = `chart-tooltip-item${
    rawNumber(value) < 0 ? ' is-danger' : (rawNumber(value) == 0 ? ' is-brand' : ' is-success')
    }`;
  return (
    <div className={CSSClass}>
      <span>To Goal</span>
      <span>
        {value}<span>%</span>
      </span>
    </div>
  );
};

ChartTooltipCards.scrapOnTotalProduction = (props) => {
  const value = dataHelpers.scrapOnTotalProduction(props);
  return (
    <div className="chart-tooltip-item is-danger">
      <span>Scrap</span>
      <span className="has-no-icon">
        {value}<span>%</span>
      </span>
    </div>
  );
};

ChartTooltipCards.scrapOnSinglePeriod = (props) => {
  const value = dataHelpers.scrapOnSinglePeriod(props);
  const valueIsValid = isInvalidNumber(rawNumber(value));
  return (
    <div className="chart-tooltip-item is-danger">
      <span>Scrap</span>
      <span className="has-no-icon">
        {value}{!valueIsValid ? <span>%</span> : null}
      </span>
    </div>
  );
};

ChartTooltipCards.calculatedScrap = (props) => {
  const value = dataHelpers.calculatedScrap(props);
  const valueIsValid = isInvalidNumber(rawNumber(value));
  return (
    <div className="chart-tooltip-item is-danger">
      <span>Scrap</span>
      <span className="has-no-icon">
        {value}{!valueIsValid ? <span>%</span> : null}
      </span>
    </div>
  );
};

ChartTooltipCards.totalProduction = (props) => {
  const { value, token } = dataHelpers.totalProduction(props.acumTotal);
  return (
    <div className="chart-tooltip-item">
      <span>Total production</span>
      <span>
        {value}<span>{token}</span>
      </span>
    </div>
  );
};

ChartTooltipCards.periodProduction = (props) => {
  const { value, token } = dataHelpers.totalProduction(props.countTotal);
  return (
    <div className="chart-tooltip-item">
      <span>Period Production</span>
      <span>
        {value}<span>{token}</span>
      </span>
    </div>
  );
};

ChartTooltipCards.efficiencyOnTotalProduction = (props) => {
  const value = dataHelpers.efficiencyOnTotalProduction(props);
  return (
    <div className="chart-tooltip-item">
      <span>OEE</span>
      <span className="has-no-icon">
        {value}<span>%</span>
      </span>
    </div>
  );
};

ChartTooltipCards.averageProductionOrderTootip = (props) => {
  const { value, token } = dataHelpers.totalProduction(props.avg_production_order);

  return (
    <div className="chart-tooltip-item">
      <span>Average Production Order</span>
      <span>
        {value}<span>{token}</span>
      </span>
    </div>
  );
};

ChartTooltipCards.cntProductionOrderTootip = (props) => {
  const { value, token } = dataHelpers.totalProduction(props.cnt_production_order);

  return (
    <div className="chart-tooltip-item">
      <span>Change over count</span>
      <span>
        {value}<span>{token}</span>
      </span>
    </div>
  );
};

export default ChartTooltipCards;
