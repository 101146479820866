import AppWrapper from 'containers/AppWrapper';
import Dashboard from 'containers/Dashboard';
import dashboardRoutes from 'containers/Dashboard/routes';
import Details from 'containers/Details';
import detailsRoutes from 'containers/Details/routes';
import Settings from 'containers/Settings';

const routes = [{
  name: 'Settings',
  path: '/app/:companyId/settings/:area',
  exact: true,
  component: AppWrapper,
  routes: [{
    name: 'Settings',
    path: '/app/:companyId/settings/:area',
    component: Settings
  }]
}, {
  name: 'Analytics',
  path: '/app/:companyId/analytics/:view/:sector/:timeRange/:component?/:componentId?/:tab?',
  component: AppWrapper,
  routes: [{
    name: 'Dashboard',
    path: '/app/:companyId/analytics/dashboard/:sector/:timeRange/:component?/:componentId?/:tab?',
    component: Dashboard,
    routes: dashboardRoutes
  }, {
    name: 'Details',
    path: '/app/:companyId/analytics/details/:sector/:timeRange/:component?/:componentId?/:tab?',
    component: Details,
    routes: detailsRoutes
  }]
}];

export default routes;
