import moment from 'moment';

const initialState = {
  isSessionActive: null,
  sectorList: [],
  updateTime: null,
  targets: [],
  customReport: null,
  loggedUser: null,
  enterpriseInfo: [],
  sitesList : []
};

const getLines = (data) => {
  let lines = (data || []).map((item) => ({ 'id': item.cd_equipment, 'name': item.cd_equipment, 'id_equipment': item.id_equipment}));
  lines.unshift({ 'id': 'ALL', 'name': 'All lines' });
  return lines;
};

const updateTargets = (targets, newtarget) => {
  let period = (newtarget.period).toLowerCase();
  let value = newtarget.value;
  let newTargets = (targets || []).map((item) => { 
    if (item.lineName === newtarget.lineName) {
      item[period] = value;
    }
    return item;
  })

  return newTargets;
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
  case 'APP_SET_USER':
    state = Object.assign({}, state, {
      isSessionActive: true,
      loggedUser: action.payload
    });
    break;
  case 'APP_DESTROY_SESSION':
    state = Object.assign({}, state, {
      isSessionActive: false,
      loggedUser: null
    });
    break;
  case 'APP_FETCH_SECTOR_LIST':
    state = Object.assign({}, state, {
      sectorList: getLines(action.payload)
    });
    break;

  case 'APP_FETCH_SITES_LIST':
    state = Object.assign({}, state, {
      sitesList: action.payload
    });
    break;
    
  case 'APP_FETCH_TARGETS':
    state = Object.assign({}, state, {
      targets: action.payload
    });
    break;
  case 'UPDATE_TARGETS_DATA':
    state = Object.assign({}, state, {
      targets: updateTargets(state.targets, action.payload)
    });
    break;
  case 'APP_FETCH_ENTERPRISE_INFO':
    state = Object.assign({}, state, {
      enterpriseInfo: action.payload
    });
    break;
  case 'USER_PERMISSIONS_DATA':
    state = Object.assign({}, state, {
      userPermissions: action.payload
    });
    break;
  case 'CUSTOM_REPORT':
    state = Object.assign({}, state, {
      customReport: action.payload
    });
    break;
    
  case 'LOGIN_SIGN_OUT':
    state = initialState;
    break;
  default:
    break;
  }
  state = Object.assign({}, state, {
    updateTime: new moment().format("HH:mm:ss")
  });
  return state;
};
