import neoPacCustom from 'containers/Custom/neopac.js';
import bispharmaCustom from 'containers/Custom/bispharma.js';


const routes = [{
  name: 'Custom',
  path: '/custom/reports/neopac/sap/:lineCd',
  exact: true,
  component: neoPacCustom
},{
  name: 'Custom',
  path: '/custom/reports/bispharma',
  exact: true,
  component: bispharmaCustom
}];

export default routes;
