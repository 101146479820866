import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Components
import LoadingContainer from 'components/LoadingContainer';
import Grid from 'components/Grid';
import EfficiencyProgressChart from 'lib/dataComponents/EfficiencyProgressChart';
import AggregationSizeSelector from 'lib/AggregationSizeSelector';
import SingleLineChart from 'lib/dataComponents/SingleLineChart';

import ShiftChart from 'lib/TotalProductionChartShifts';
import EfficiencyProgressBars from '../components/EfficiencyProgressBars';
import EfficiencyProgressSelector from 'lib/EfficiencyProgressSelector';

// Utils
import RouterUtils from 'utils/RouterUtils';
import { hashCode } from 'utils/utilsFn';

// Constants
import { colors } from 'core/constants';

import shiftsMock from '../components/shiftsMock';
import teamsMock from '../components/teamsMock';

export class EfficiencyProgress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      otherGraphs: false
    };
  }

  componentWillMount(){
    this.limitLineAcess();
  }
  componentWillUpdate(){
    this.limitLineAcess();
  }


  render() {
    // Save isFetching prop
    const isFetching = this.props.isFetching;

    // Set all the constants necessary to hide or show Production Flow, Events and Current Production
    const isDay = RouterUtils.constant('isDay');
    const isAllLines = RouterUtils.constant('isAllLines');
    const isOneLine = RouterUtils.constant('isOneLine');
    const isShift = this.props.match.params.sector === 'shift';
    const isTeam = this.props.match.params.sector === 'team';

    let legendKeys = [];

    let chartArray = [...this.props.efficiencyProgress];

    if (isShift && this.props.efficiencyProgress.length > 0 && this.props.efficiencyProgress[0] && this.props.efficiencyProgress[0].shifts) {
      
      legendKeys = [];
      chartArray[0].shifts.forEach((item, index) => {
        legendKeys.push({
          label: 'Shift ' + item.shift,
          color: colors.secondary[index]
        });
      });
    }else if(isTeam && this.props.efficiencyProgress.length > 0 && this.props.efficiencyProgress[0] && this.props.efficiencyProgress[0].teams){
 

      legendKeys = [];
      chartArray[0].teams.forEach((item, index) => {
        legendKeys.push({
          label: 'Team ' + item.team,
          color: colors.secondary[index]
        });
      });
    }

    let chartIcons = [{
      label: 'OEE',
      color: colors.primary.blue,
      type: "line"
    },{
      label: 'Change Over Count',
      color: colors.primary.blue,
      type: "bar"
    }]
    legendKeys = legendKeys.concat(chartIcons);
    return (
      <React.Fragment>

        <AggregationSizeSelector
          current={this.props.aggregationSize}
          onChange={this.handleAggregationSize.bind(this)}
        />

        <Grid>
          <Grid.Col>

            <LoadingContainer isActive={isFetching.production}>

              <EfficiencyProgressChart
                chartData={chartArray}
                legendKeys={legendKeys}
                isMultiline={(isShift || isTeam)}
                isTeam={isTeam}
              />

            </LoadingContainer>

          </Grid.Col>
        </Grid>
        
        {/*<EfficiencyProgressSelector
          current={this.state.otherGraphs}
          onChange={this.handleShowOtherGraphs.bind(this)}
        />*/}

        {/*!isShift && this.state.otherGraphs ? <EfficiencyProgressBars {...this.props} /> : null*/}
      </React.Fragment>
    );
  }

  handleAggregationSize({ target }) {
    this.props.updateAggregationSize(target.value);
  }

  handleShowOtherGraphs({ target }) {
    this.setState({ otherGraphs: !this.state.otherGraphs });
  }

  limitLineAcess(){
    if(this.props.match.params.componentId == 'ALL' && this.props.match.params.sector != 'factory'){
      this.props.history.push(
        '/app/1/analytics/dashboard/'+
        this.props.match.params.sector+
        "/"+this.props.match.params.timeRange+
        "/"+this.props.match.params.component+"/ALL/total-production"
      );
    }
  }


}

EfficiencyProgress.defaultProps = {
  isFetching: {},
  updateAggregationSize: () => { },
  aggregationSize: null,
  production: []
};

EfficiencyProgress.propTypes = {
  efficiencyProgress: PropTypes.array.isRequired,
  updateAggregationSize: PropTypes.func,
  aggregationSize: PropTypes.string,
  production: PropTypes.array
};

export default connect((store) => ({
  production: store.Dashboard.production,
  efficiency: store.Dashboard.efficiency,
  efficiencyProgress: store.Dashboard.efficiencyProgress,
  events: store.Dashboard.events,
  currentProduction: store.Dashboard.currentProduction,
  productionFlow: store.Dashboard.productionFlow,
  isFetching: store.Dashboard.isFetching
}), actions)(EfficiencyProgress);
