import { uuid } from 'utils/utils';

const initialState = {
  eventKey: uuid(),
  chartData: [],
  cardsData: [],
  tableData: [],
  ordersData: [],
  shiftData: [],
  lineStatus: null
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
  case 'OVERVIEW_DATA':
    state = Object.assign({}, state, {
      chartData: action.payload
    });
    break;
  case 'OVERVIEW_SHIFT_DATA':
    state = Object.assign({}, state, {
      shiftData: action.payload
    });
    break;
  case 'OVERVIEW_DOWNTIME_DATA':
    state = Object.assign({}, state, {
      tableData: action.payload
    });
    break;
  case 'OVERVIEW_ORDERS_DATA':
    state = Object.assign({}, state, {
      ordersData: action.payload
    });
    break;
  case 'OVERVIEW_LINE_STATUS':
    state = Object.assign({}, state, {
      lineStatus: action.payload
    });
    break;
  default:
    break;
  }

  return state;
};
