import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import * as actions from '../../actions';

// Components
import Grid from 'components/Grid';
import DataTable from 'lib/DataTable';
import TimelineBar from 'lib/TimelineBar';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Filter from 'lib/Filter';

import { convertToDaysHours } from 'utils/utilsFn';
import { percent } from 'utils/utils';
import { CSVLink } from "utils/react-csv";
import moment from 'moment';

import compareParams from 'utils/compareParams';

export class Downtime extends React.PureComponent {
  constructor(props) {
    super(props);
    //routerProps(this);
    this.state = {
      isFilterOpen: false,
      suggestions: [],
      reasons: false
    };
    
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount(){
    this.fetchAllData(this.props);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (compareParams(this.props, prevProps) || prevState.aggregationSize !== this.state.aggregationSize) {
      this.fetchAllData(this.props);
    }
    return null;
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.sectorList.length > 0 && !this.props.stopReasons && !this.state.reasons && this.props.sectorList[this.props.sectorList.length-1]){
      this.props.getReasons(this.props.sectorList[this.props.sectorList.length-1].id_equipment);
      this.setState({reasons: true});
    }
  }

  fetchAllData(props) {
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId
    };
    
    this.props.fetchDowntime(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchDowntimeReasons(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    this.props.fetchEfficiencyData(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    });
    if(this.props.sectorList[this.props.sectorList.length-1]){
      this.props.getReasons(
        props.sectorList[props.sectorList.length-1].id_equipment
      );
    }
  }

  handleClick() {
    if (!this.state.isFilterOpen) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      isFilterOpen: !prevState.isFilterOpen,
    }));
  }
  
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node != null && (this.node.contains(e.target) ||e && e.target.className == "ReactTags__remove")) {
      return;
    }
    this.handleClick();
  }


  fetchFilter(fields){
    setTimeout(function (){
      this.setState({
        isFilterOpen: false,
      });
    }.bind(this), 500);
    
    var props = this.props;
    const nextParams = props.match.params;
    const mainParams = {
      component: nextParams.component,
      componentId: nextParams.componentId
    };
    this.props.fetchDowntimeReasons(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    },fields);
    this.props.fetchDowntime(mainParams, {
      timeRange: nextParams.timeRange,
      aggregationSize: this.state.aggregationSize
    },fields);

    
  }


  render() {
    // Save isFetching prop
    const isFetching = this.props.isFetching;

    let stoppedTime = (this.props.efficiencyData && this.props.efficiencyData[1])? this.props.efficiencyData[1].totaltime.value : 0;
    let totalTime = 0;
    (this.props.efficiencyData || []).forEach((item) => totalTime += item.totaltime.value);

    const mapKeys = {
      'Line': {
        name: 'Line',
        format: 'string',
        orderBy: 'Line'
      },
      'shift': {
        name: 'Shift',
        format: 'string',
        orderBy: 'Shift',
        filter: true,
        filterType: 'input'
      },
      'Start': {
        name: 'Start',
        orderBy: 'Start.value'
      },
      'End': {
        name: 'End',
        orderBy: 'End.value'
      },
      'Total time': {
        name: 'Total time',
        csvName: 'Total time (s)',
        orderBy: 'Total time.value',
        filter: true,
        filterType: 'combobox',
        filterValues: [{text: '--', min: null, max: null},{text: '< 10 min', min: 0, max: 600},{text: '10 to 20 min', min: 600, max: 1200},{text: '20 min to 1 hour', min: 1200, max: 3600}, {text: '1 to 3 hours', min: 3600, max: 10800}, {text: 'over 3 hours', min: 10800, max: 999999999}]
      },
      'nm_reason': {
        name: 'Category',
        format: 'tooltip',
        orderBy: 'Reason',
        filter: true,
        filterType: 'tag',
        mask:"txt_downtime_reason"
      },
      'nm_reason_subcategory':{
        name: 'Sub Category',
        format: 'tooltip',
        orderBy: 'nm_reason_subcategory',
        mask:"txt_reason_subcategory"
      },
      'cd_machine': {
        name: 'Machine',
        format: 'string',
        orderBy: 'cd_machine'
      },
      'downtime_note': {
        name: 'Notes',
        format: 'string',
        orderBy: 'Notes',
        shrink: true,
        style: {
          maxWidth: 180,
          whiteSpace: 'normal',
          fontSize: 14,
          fontWeight: 200
        },
        filter: true,
        filterType: 'input'
      }
    }

    let csvHeader = (Object.keys(mapKeys) || []).map((key) => {
      if(mapKeys[key].csvName){
        return ({label: mapKeys[key].csvName, key: key });
      }
      return ({label: mapKeys[key].name, key: key });
    });

    let csvData = (this.props.downtimeData || []).map((item) =>{
      return({
        'Line': item.Line,
        'shift': item.shift,
        'Start':  moment(item.Start.value).format('DD-MM-YYYY HH:mm'),
        'End': moment(item.End.value).format('DD-MM-YYYY HH:mm'),
        'Total time': item['Total time'].value,
        'cd_machine': item.cd_machine,
        'nm_reason': item.nm_reason,
        'nm_reason_subcategory': item.nm_reason_subcategory,
        'downtime_note': item.downtime_note ? item.downtime_note.replace(/(\r\n|\n|\r)/gm, " ") : '',
      })
    });
 

    var suggestion = (this.props.stopReasons  || []).map((item) => ({ 'id': item.cd_downtime_reason, 'text': item.nm_downtime_reason}));
    if(JSON.stringify(suggestion)!=JSON.stringify(this.state.suggestions)){
      this.setState({suggestions: suggestion})
    }


    return (
      <React.Fragment>
        <Grid>
          <Grid.Col className="is-hidden-touch relative">
            <div className="v-space-small" />
            <div>              
              <h3 className="title is-6 is-uppercase">Downtime reasons&nbsp;<i>{!this.props.isFetching.efficiencyData ?' - Total Downtime ' +convertToDaysHours(stoppedTime, 'seconds')+" - "+percent(totalTime, stoppedTime, 1)+"%":<Icon name="sync infinite-sync-animation" />}</i></h3>
              <Button specSelector="settings" className="filter" theme="brand-medium-grey"  onClick={() => {this.handleClick()}} bare medium>
                <Icon name="sliders-h" />
              </Button>
              {this.props.downtimeData?
              <CSVLink data={csvData} separator={";"}  className="button is-brand-medium-grey is-bare is-medium filter" filename={"downtime-reasons-"+moment().format("YYYY-MM-DD")+".csv"} headers={csvHeader}>
                <Icon name="file-csv" fal={true} />
              </CSVLink>
              :null}
              <div ref={node => { this.node = node; }}>
                <Filter mapKeys={mapKeys} isFilterOpen={this.state.isFilterOpen} save={this.fetchFilter.bind(this)} suggestions={this.state.suggestions} innerRef={el => { this.el = el }}/>
              </div>
            </div>
            <hr className="hr is-small" />
            <TimelineBar data={this.props.downtimeTypes} isFetching={this.props.isFetching} fetchingKey={'downtimeReasons'} customTooltip="downtimeTypes" format="hours" />
            <hr className="hr is-small" />
            <TimelineBar data={this.props.downtimeReasons} hide isFetching={this.props.isFetching} fetchingKey={'downtimeReasons'}  customTooltip="downtimeReasons" format="hours" />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col className="datatable-wrapper">
            <DataTable
              mapKeys={mapKeys}
              data={this.props.downtimeData}
              isFetching={this.props.isFetching}
              downtime
              fetchingKey={'downtime'}
            />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

}

Downtime.defaultProps = {
  isFetching: {},
  downtimeData: [],
  downtimeReasons: []
};

Downtime.propTypes = {
  isFetching: PropTypes.object,
  downtimeData: PropTypes.array,
  downtimeReasons: PropTypes.array
};

export default connect((store) => ({
  sectorList: store.app.sectorList,
  downtimeData: store.Details.downtimeData,
  downtimeReasons: store.Details.downtimeReasons,
  downtimeTypes: store.Details.downtimeTypes,
  isFetching: store.Details.isFetching,
  efficiencyData: store.Details.efficiencyData,
  stopReasons : store.Details.stopReasons,
  clientsList: store.Details.clientsList
}), actions)(Downtime);
