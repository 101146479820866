import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Components
import Layout from 'components/Layout';
import Box from 'components/Box';
import Picture from 'components/Picture';
import SplashScreen, { withSplashScreen } from 'components/SplashScreen';

// Assets
import packiotLogo from 'static/media/packiot-dark.svg';

let timer = setTimeout;

export class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showAlert: false,
      startTransition: false,
      errorAuth: false,
      isAuth: false
    };
  }

  componentWillMount() {
    //this.props.LogoutFirebaseLogin();
  }

  redirectToLogin(){
    this.props.history.push('/login');
  }

  render() {
    return (
      <Layout vhCentered>
        <SplashScreen visible={this.state.startTransition} />
        <Box maxWidth={300} padding="medium">
          <Picture src={packiotLogo} alt="PackIOT - Data Matters" maxWidth={220} centered />
          <hr />
          <p className="has-text-centered">Access not authorized</p>
          <p><a onClick={this.redirectToLogin.bind(this)}>Click here</a> to go to the login page</p>
        </Box>
      </Layout>
    );
  }
}
Login.propTypes = {};
export default connect((store) => ({}))(withSplashScreen(Login));
