import { combineReducers } from 'redux';

import AppWrapper from '../containers/AppWrapper/reducer';
import Login from '../containers/Login/reducer';
import Dashboard from '../containers/Dashboard/reducer';
import Details from '../containers/Details/reducer';
import Settings from '../containers/Settings/reducer';
import Overview from '../containers/Overview/reducer';
import Custom from '../containers/Custom/reducer';

export default combineReducers({
  app: AppWrapper,
  login: Login,
  Dashboard: Dashboard,
  Details: Details,
  Settings: Settings,
  Overview: Overview,
  Custom: Custom
});
