export default [{
  acumTotal: null,
  countTotal: null,
  grossacum: null,
  scrap: null,
  scrapacum: null,
  shift: 1,
  team: 'A',
  timestamp: null
}, {
  acumTotal: null,
  countTotal: null,
  grossacum: null,
  scrap: null,
  scrapacum: null,
  shift: 2,
  team: 'B',
  timestamp: null
}, {
  acumTotal: null,
  countTotal: null,
  grossacum: null,
  scrap: null,
  scrapacum: null,
  shift: 3,
  team: 'C',
  timestamp: null
}, {
  acumTotal: null,
  countTotal: null,
  grossacum: null,
  scrap: null,
  scrapacum: null,
  shift: 4,
  team: 'D',
  timestamp: null
}];
