import { uuid } from 'utils/utils';

const initialState = {
  eventKey: uuid(),
  productionData: null,
  production: null,
  efficiencyData: null,
  ordersData: null,
  downtimeData: null,
  downtimeReasons: null,
  downtimeTypes: null,
  stopReasons: null,
  scrapDetails: null,
  targets: null,
  error : null,
  clientsList: null,
  manualEvents: null,
  isFetching: {
    scrapDetails: false,
    efficiencyData: false,
  },
  isUpdatingData: uuid(),
  errorRequest: []
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
  case 'IS_FETCHING':
    let isFetching = { ...state.isFetching };
    if (action.dataType === 'all') {
      Object.keys(isFetching).forEach((item) => isFetching[item] = action.payload);
    } else {
      isFetching = { ...isFetching, ...{ [action.dataType]: action.payload } };
    }
    state = { ...state, ...{ isFetching: isFetching } };
    break;
  case 'FETCH_PRODUCTION_DATA':
    state = Object.assign({}, state, {
      productionData: action.payload.productProduction
    });
    break;
  case 'FETCH_EFFICIENCY_DATA':
    state = Object.assign({}, state, {
      efficiencyData: action.payload.lineStatus
    });
    break;
  case 'FETCH_ORDERS_ERROR':
    state = Object.assign({}, state, {
      error: true
    });
    break;

  case 'PRODUCTION':
    state = Object.assign({}, state, {
      production: action.payload.lineChartData,
      isUpdating: uuid(),
      errorRequest: { 'productionData': action.errorRequest }
    });
    break;

  case 'PRODUCTION_DATA':
    state = Object.assign({}, state, {
      productionData: action.payload.lineChartData,
      isUpdatingData: uuid(),
      errorRequest: { 'productionData': action.errorRequest }
    });
    break;
  case 'FETCH_DOWNTIME':
    state = Object.assign({}, state, {
      downtimeData: action.payload.lineStatus,
      isUpdatingData: uuid(),
      errorRequest: { 'downtimeData': action.errorRequest }
    });
    break;
  case 'FETCH_DOWNTIME_REASONS':
    state = Object.assign({}, state, {
      downtimeReasons: action.payload.downtimeReasons,
      downtimeTypes: action.payload.downtime_types,
      isUpdatingData: uuid(),
      errorRequest: { 'downtimeReasons': action.errorRequest }
    });
    break;
  case 'FETCH_ORDERS':
    state = Object.assign({}, state, {
      ordersData: action.payload,
      isUpdatingData: uuid(),
      error: false,
      errorRequest: { 'ordersData': action.errorRequest }
    });
    break;
  case 'FETCH_SCRAP':
    state = Object.assign({}, state, {
      scrapDetails: action.payload,
      isUpdatingData: uuid(),
      errorRequest: { 'scrapDetails': action.errorRequest }
    });
    break;
  case 'FETCH_TARGETS':
    state = Object.assign({}, state, {
      targets: action.payload,
      isUpdatingData: uuid(),
      errorRequest: { 'targets': action.errorRequest }
    });
    break;
  case 'FETCH_CLIENTS':
    state = Object.assign({}, state, {
      clientsList: action.payload,
      isUpdatingData: uuid(),
      errorRequest: { 'clients': action.errorRequest }
    });
    break;
  case 'STOP_REASONS':
    state = Object.assign({}, state, {
      stopReasons: action.payload,
      isUpdatingData: uuid(),
      errorRequest: { 'stopReasonsError': action.errorRequest }
    });
    break;
  case 'MANUAL_EVENTS':
    state = Object.assign({}, state, {
      manualEvents: action.payload,
      isUpdatingData: uuid(),
      errorRequest: { 'manualEvents': action.errorRequest }
    });  
    break;
  case 'UPDATE_DATA':
    state = Object.assign({}, state, { eventKey: uuid() });
    break;
  case 'LOGIN_SIGN_OUT':
    state = initialState;
    break;
  default:
    break;
  }
  return state;
};
