import React from 'react';
import RouterUtils from 'utils/RouterUtils';

import Input from 'components/Input';
import Grid from 'components/Grid';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import Form from 'components/Form';
import Button from 'components/Button';
import { WithContext as ReactTags } from '../utils/react-tags/components/ReactTags'/*'react-tag-input'*/;

export class Filter extends React.Component {

    constructor(props) {
      super(props);
      //routerProps(this);
      this.state = {
        filterCss: [],
        isFilterOpen: false,
        filters:{},
        tags: [],
        suggestions: this.props.suggestions
      };

      this.handleDelete = this.handleDelete.bind(this);
      this.handleAddition = this.handleAddition.bind(this);
      this.handleDrag = this.handleDrag.bind(this);
    }

    componentWillReceiveProps(props) {
      if(JSON.stringify(props.suggestions)!=JSON.stringify(this.state.suggestions)){
        this.setState({suggestions:props.suggestions})
      }
    }

    handleDelete(i) {
      const { tags } = this.state;
      this.setState({
        tags: tags.filter((tag, index) => index !== i),
      });
    }

    handleAddition(tag) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
    }
    
    handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
    } 
    

    handleFilterInput(type, field, callback) {
    
      const fieldValue = typeof field === 'string' ? field : field.target.value;
      let items = this.state.filters;
      items[type] = fieldValue || null;
      if(items[type] == null){
        delete items[type]; 
      }
      this.setState({
        filters: items
      });
      if (callback) callback();
    }

    handleChange(type, field, callback) {
      const fieldValue = typeof field === 'string' ? field : field.target.value;
      let items = this.state.currentItem;
      let value = fieldValue || null;
      items[type] = value;
      if (type === 'value' && !!parseInt(fieldValue)) {
        items[type] = parseInt(value);
      }
      if (callback) callback();
    }


    resetFilters() {
      this.setState({
        filters: {},
        tags: []
      });
    }

    save() {
      let tagFilter = "";
      let query = "";

      Object.keys(this.props.mapKeys).map((subItem) => {
        let keyColunm = subItem;
        let filter = this.props.mapKeys[subItem].filter;
        let filterType = this.props.mapKeys[subItem].filterType;

        if(filter && (filterType== 'tag' || this.state.filters[keyColunm])){
          if(filterType == 'tag'){
            tagFilter = (keyColunm);
          }else if(filterType == 'combobox'){
            if(this.state.filters[keyColunm].min != null && this.state.filters[keyColunm].max != null){
              query += "&" + keyColunm+"min" + "=" + this.state.filters[keyColunm].min;
              query += "&" + keyColunm+"max" + "=" + this.state.filters[keyColunm].max;
            }
            
          }else{
            query += "&" + keyColunm + "=" + this.state.filters[keyColunm];
          }
        }
      });
      if(this.state.tags.length > 0){
        query += "&" + tagFilter + "=";
        Object.keys(this.state.tags).map((tagKey) => {
          query += this.state.tags[tagKey].id + ',';
        });
        query = query.substr(0, query.length-1);
      }
      this.props.save(query);
    }
    

    render(){

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const { tags, suggestions } = this.state;

    let filterCSS = (this.props.isFilterOpen) ? 'is-active' : '';

    var tagFilter = Object.keys(this.props.mapKeys).map((subItem, subIndex) => {
        let keyColunm = subItem;
        let filter = this.props.mapKeys[subItem].filter;
        let filterType = this.props.mapKeys[subItem].filterType;
        if(filter && filterType == 'tag'){
          return (
          <Grid.Col size="three-quarters">
            <h3 className="title is-6 is-uppercase">
              {this.props.mapKeys[subItem].name}
            </h3>
            <ReactTags tags={tags}
            autocomplete={1}
            minQueryLength={1}
            suggestions={suggestions}
            handleDelete={this.handleDelete}
            handleAddition={this.handleAddition}
            handleDrag={this.handleDrag}
            delimiters={delimiters}
            placeholder={"--"} />
             
          </Grid.Col>);
        }
    });
            

    var filters = Object.keys(this.props.mapKeys).map((subItem, subIndex) => {
        let keyColunm = subItem;
        let filter = this.props.mapKeys[subItem].filter;
        let filterType = this.props.mapKeys[subItem].filterType;
        if(filter && filterType != 'tag'){
          return (
          <Grid.Col>
            <h3 className="title is-6 is-uppercase">
              {this.props.mapKeys[subItem].name}
            </h3>
            {(filterType == "input")?
              <Input placeholder="--" onChange={this.handleFilterInput.bind(this, keyColunm)} value={this.state.filters[keyColunm] || ""} />
            
            :(filterType == "combobox")?
              <Dropdown isField trigger={<React.Fragment><span>{(this.state.filters[keyColunm]) ? this.state.filters[keyColunm].text : '--'}</span> <Icon name="angle-down" /></React.Fragment>}>
                {this.props.mapKeys[subItem].filterValues.map((item) => (
                  <button
                    type="button"
                    className={'dropdown-item'}
                    key={item.value}
                    onClick={() => {
                      let newFilters = this.state.filters;
                      newFilters[keyColunm] = item
                      this.setState({ filters: newFilters });
                    }}
                    >
                    {item.text}
                  </button>
                  ))
                }
              </Dropdown>
  
            :null
            
            }
            
          </Grid.Col>);
        }
    });

    return (
        <div className={"filters "+filterCSS}>
          <Form onSubmit={this.save.bind(this)}>
            <Grid>
                {filters}
            </Grid>
            <Grid>
                {tagFilter}
                <Grid.Col className="is-vcentered is-paddingless">
                    <div className="has-text-centered  buttons">
                        <Button className="is-gray" onClick={this.resetFilters.bind(this)} >Reset</Button>
                        <Button className="is-blue" submit>Apply</Button>
                    </div>
                </Grid.Col>
            </Grid>
          </Form>
        </div>
    );
    }

};

export default Filter;