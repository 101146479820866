//import * as firebase from 'firebase';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const FirebaseConfig = {
  apiKey: 'AIzaSyCRK02fBbgho-VSQrjt5bIZzzVdoIgpRGo',
  authDomain: 'fbpackiot.firebaseapp.com',
  databaseURL: 'https://fbpackiot.firebaseio.com',
  projectId: 'fbpackiot',
  storageBucket: 'fbpackiot.appspot.com',
  messagingSenderId: '969023787798'
};

firebase.initializeApp(FirebaseConfig);

const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);
const auth = firebase.auth();
const equipments = db.collection('equipments');

export {
  auth,
  equipments,
  db,
  firebase
};