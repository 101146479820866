import { uuid } from 'utils/utils';

const initialState = {
  saveIsSuccess: uuid(),
  csvError: false,
  message: '',
  goalData: [],

  isFetching: {
    csvUpload: false,
    customTarget: false,
  },
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
    case 'IS_FETCHING':
      let isFetching = { ...state.isFetching };
      if (action.dataType === 'all') {
        Object.keys(isFetching).forEach((item) => isFetching[item] = action.payload);
      } else {
        isFetching = { ...isFetching, ...{ [action.dataType]: action.payload } };
      }
      state = { ...state, ...{ isFetching: isFetching } };
      break;
    case 'SAVE_TARGETS_DATA':
      state = Object.assign({}, state, {
        saveIsSuccess: uuid()
      });
      break;
    case 'SAVE_TARGETS_DATA_ERROR':
      state = Object.assign({}, state, {
        saveIsSuccess: uuid()
      });
      break;
    case 'SAVE_DATACSV':
      state = Object.assign({}, state, {
        saveIsSuccess: uuid(),
        csvError: false,
      });
      break;
    case 'SAVE_DATACSV_ERROR':
      state = Object.assign({}, state, {
        csvError: true
      });
      break;
    case 'FETCH_DATACSV':
      state = Object.assign({}, state, {
        saveIsSuccess: uuid(),
        dataCsv: action.payload
      });
      break;
    case 'FETCH_DATA_GOAL_PERIOD':
      state = Object.assign({}, state, {
        goalData: action.payload
      });
      break;
    case 'LOGIN_SIGN_OUT':
      state = initialState;
      break;
    default:
      break;
  }

  return state;
};
